import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {formatDate, formatNumber, getNotyfObject} from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderWrapper, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper,RJContainer,TRJContainer } from './InvoiceDetail.styled';
import IDProgressLine from '../../components/InvoiceDetail/IDProgressLine/IDProgressLine';
import InvoiceView from '../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView';
import InvoiceSide from '../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
function InvoiceDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        getInvoiceData,
        resetAdmToInitialState,
        userProfile,
        userType,
        statuses
    } = props
    let {uid, token} = useParams();
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const [active, setActive] = useState(false);
 
    const [navTabsList, setNavTabsList] = useState([])

    const typeInvoiceToNavListe=(xml,exportedXml,edi,pdf,chorus,alfresco)=>{
        var list=[];
        if(pdf!==null)
        list=([...list,
            {label: 'PDF', value: 'pdf'}   
        ])
        if(xml!==null && userType === "owner")
            list=([...list,
                {label: 'XML', value: 'xml'}   
            ])
        if(exportedXml && userType === "owner")
            list=([...list,
                {label: 'XML exporté', value: 'exportedXml'}   
            ])
        if(edi!==null)
            list=([...list,
                {label: 'EDI', value: 'edi'}   
            ])
        if(chorus!==null)
            list=([...list,
                {label: 'CHORUS', value: 'chorus'}   
            ])
        if(userType === "owner" && ["ADMIN", "REJECT_MANAGER"].includes(userProfile))
            list=([...list,
                {label: 'GED' + (!alfresco ? ' (indisponible pour le moment, en attente de réception par AGSRM)' : ''), value: alfresco ? alfresco : null}   
            ])
  
      setNavTabsList(list)
    }

    useEffect(() => {
        typeInvoiceToNavListe(invoiceData?.xmlFile, invoiceData?.exportedXmlFile ,invoiceData?.ediFile ,invoiceData?.docFile,invoiceData?.chorusFile, invoiceData?.alfrescoLink)
    }, [invoiceData?.xmlFile, invoiceData?.exportedXmlFile, invoiceData?.ediFile ,invoiceData?.docFile,invoiceData?.chorusFile, invoiceData?.alfrescoLink])

    useEffect(() => {
        if(token) {
            getInvoiceDataByToken(token)
        } else {
            getInvoiceData(uid)
        }
        
        return () => {
            resetAdmToInitialState()
        }

        
    }, [uid, token])

    const editStatus = (status, comment = null,  reason = null) => {
        Axios.put('/invoice//updateStatus', 
              { 
                  "InvoiceId": uid,
                  "status" : status,
                  reason,
                  comment
        }).then(response => {
            getInvoiceData(uid)
            notyf.success("Statut modifié")
        }).catch(err => {
            notyf.error("Une erreur s'est produite")
        })
    }
    
    let mandatJsx = null;

    // if(error) {
    //     notyf.error(t("mandat:generatedErrorMessage"));
    // }

    if(loadingData) mandatJsx = (<Spinner />)
    
    else if (invoiceData)
        mandatJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <IDHeaderTitle style={{paddingRight: "100px"}}>
                            {(userProfile!=="ACCOUNTING" && userProfile!=="ACCOUNTING_TLV") ?
                            <>
                                {t("invoiceDetails:"+(invoiceData?.documentType == "CRN" ? "creditNote" : "invoice"), "Invoice:")} {invoiceData?.number}
                                &nbsp;({invoiceData?.codeClient} {invoiceData?.clientName} - {invoiceData?.codeSupplier} {invoiceData?.supplierName})
                            </>
                            : 
                            <>
                                    Code SU : {invoiceData?.codeClient?invoiceData?.codeClient:"xxxxx"}&nbsp;-
                                    Code doc : {invoiceData?.documentCode} - Numéro de pièce : {invoiceData?.internalNumber}
                            </>
                            
                                }
                            </IDHeaderTitle>
                            {
                                invoiceData?.status !== "ERROR" && (userProfile!=="ACCOUNTING" && userProfile!=="ACCOUNTING_TLV") &&
                                    <IDHeaderTitle>
                                        {formatNumber(invoiceData?.inclusiveTaxAmount)} {invoiceData?.currency} {t("global:ttc", "including taxes")}
                                    </IDHeaderTitle>
                            }
                        </IDHeader>
                        {invoiceData?.invoiceIfCreditNote && <IDHeader>
                            <IDHeaderTitle>
                                {t("invoiceDetails:"+(invoiceData?.documentType == "INV" ? "creditNote" : "originalInvoice"), "Original invoice:")}&nbsp;
                                <a style={{color: "red"}} href={"/invoiceDetail/"+invoiceData?.invoiceIfCreditNote}>{invoiceData?.invoiceNumberIfCreditNote}</a>
                            </IDHeaderTitle>
                        </IDHeader>}
                        <IDProgressLineWrapper>
                            <IDProgressLine
                                invoiceUid={uid}
                                status={invoiceData?.status}
                                statuses={statuses}
                                isCodaRefused={invoiceData?.codaRefused}
                                editStatus={editStatus}
                                accessToken={token}
                            />
                            {
                                invoiceData?.status !== "ERROR" &&
                                    <IDInvoiceDate>
                                        <IDInvoiceDateLabel>{t("invoiceDetails:issuingDate", "Issuing date:")}</IDInvoiceDateLabel>
                                        <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue>
                                    </IDInvoiceDate>
                            }
                        </IDProgressLineWrapper>
                    </IDHeaderContainer>
                    {
                        !token && 
                            <IDHeaderActions>

                                {/* <RJContainer>
                                    <BtnOutlineAction
                                            color={"#8c8c8c"}
                                            border={"#8c8c8c"}
                                            onClick={() => BtnClickHandler()} 
                                            >
                                            traitement
                                    </BtnOutlineAction>
                                </RJContainer> */}
                            </IDHeaderActions>
                            
                    }



                </IDHeaderWrapper>

                <IDBody accessToken={token}>
                    <InvoiceView navTabsList={navTabsList}/>
                    {
                        !token ?
                            <InvoiceSide statuses={statuses}/>  
                        : null
                    }     
                </IDBody>
            </>
        )

    return (
        <>
            <IDContainer>
                <TitleHelmet title={"Transdev | " + t("menu:invoiceDetails", 'Invoice details')} />
                {mandatJsx}
            </IDContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingData: state.invoiceDetail.loadingData,
    invoiceData: state.invoiceDetail.invoiceData,
    invoiceError: state.invoiceDetail.invoiceError,
    userProfile : state.auth.profile,
    userType : state.auth.userType,
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState : () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail)
