import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const notyf = new Notyf({
    types: [
        {
            type: 'warning',
            background: 'orange',
            duration: 5000,
            dismissible: true,
            position: {x: "center", y: "center"}
          },
          {
            type: 'error',
            background: 'indianred',
            duration: null,
            dismissible: true,
            position: {x: "center", y: "center"}
          },
          {
            type: 'success',
            duration: 7000,
            dismissible: true,
            position: {x: "center", y: "center"}
          }
    ]})

    const notyf10s = new Notyf({
        types: [
            {
                type: 'warning',
                background: 'orange',
                duration: 5000,
                dismissible: true
              },
              {
                type: 'error',
                background: 'indianred',
                duration: 9000,
                dismissible: true
              }
        ]
    });

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const formatDate = (date) => {
    if (date !== null && date.valueOf() > 0){
        let newDate = new Date(date);
        let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
        let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
    return day + '/' + month + '/' + newDate.getFullYear()
    }else return null;    
}
export const dayForIntervalMonth = (monthOffset = 0, dayOffset = 0) => {
    const monthList = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
    const moonLanding = new Date();
    const now = new Date();
    now.setMonth(now.getMonth()+monthOffset)
    now.setDate(now.getDate()+dayOffset)
    moonLanding.setMonth(moonLanding.getMonth()-1+monthOffset)
    moonLanding.setDate(moonLanding.getDate()+dayOffset)
    var daysOfYear = [];
    for (var d = moonLanding; d < now; d.setDate(d.getDate() + 1)) {
        var day=d.getDate().toString()
        if (day.length==1){
            day="0"+d.getDate()
            
        }
        let month=  monthList[d.getMonth()].substring(0, 3)
        let year=  d.getFullYear().toString()
        let yearS=year.substring(2, 4)
    daysOfYear.push(day+"/"+month+"/"+yearS);
    }
    
      return(daysOfYear)
}
export const dayForIntervalMonthWithKey = (monthOffset = 0, dayOffset = 0) => {
    const moonLanding = new Date();
    const now = new Date();
    now.setMonth(now.getMonth()+monthOffset)
    now.setDate(now.getDate()+dayOffset)
    moonLanding.setMonth(moonLanding.getMonth()-1+monthOffset)
    moonLanding.setDate(moonLanding.getDate()+dayOffset)
 
    var i=0;
    var daysOfYear = [];
    for (var d = moonLanding; d < now; d.setDate(d.getDate() + 1)) {
        let element = {};
        element.key = d.getDate();
        element.value =i;
        daysOfYear.push(element);
        i++;
    }
      return(daysOfYear)
}

export const formatDateEur = (date) => {
    if (date !== null && date.valueOf() > 0){
        let newDate = new Date(date);
        let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
        let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
    return newDate.getFullYear() + '-' + month + '-' + day
    }else return null;    
}
export const getStatusWithKey = (key, userType = null) => {
    const status = {
        NEW : "Reçue par la plateforme",
        CONTROLLED : "Contrôlée",
        TO_BE_PAID : "A payer",
        PAID : "Encaissée",
        LITIGATION : "Litige",
        IMPORTED : "Déposée",
        INTEGRATED : userType === 'client' ? "Reçue" : "Intégrée",
        REFUSED : "Refusée",
        REJECTED : "Rejetée",
        ERROR : 'Erreur'
    }
    return status[key]
}
export const formatChannel=(channel)=>{
    const channelType = {
        
          'EDI': 'EDI',
          'PAPER': 'Papier',
          'EMAIL': 'Email',
          'PORTAL': 'Portail',
          'CHORUS': 'Chorus',
          'PDP': 'PDP',
          'PPF': 'PPF',

    }
    return channelType[channel]
}
export const formatType=(channel)=>{
    const channelType = {
        'INV':'Facture',
        'CRN':'Avoir',

    }
    return channelType[channel]
}
export const LastActionAuthorformatType=(channel)=>{
    const channelType = {
        'ACCOUNTING':'Dernière action par comptable four',
        'ACCOUNTING_TLV':'Dernière action par comptable four',
        'SUPPLIER':'Dernière action par fournisseur',
        'REJECT_MANAGER':'Dernière action par gestionnaire conformité',
        'BUYER':'Dernière action par gestionnaire Achats',
        'ADMIN':'Dernière action par administrateur portail',
        'RVCA':'Retour en vidéo-codage',
        'DATA_CHANGE':"Changement de code SU et/ou de type d'erreur"

    }
    return channelType[channel]
}


export const showColumn=(column,field)=>{
    if( column.filter(column => column.field==field)[0]?.show == true)
    return true 
    else 
    return false

 }
export const getEntityStatusWithKey = (key) => {
    const status = {
        ACTIVE : 'Actif',
        INACTIVE : "Inactif",
        INPROGRESS : "En cours",
        ARCHIVED : "Archivé",
    }
    return status[key]
}
export const getEreportingTypeeWithKey = (key) => {
    const status = {
        RECEIVED : 'Fournisseur',
        SENT : "Client",
       
    }
    return status[key]
}
export const getChannelWithKey = (key) => {
    const status = {
        EDI : 'EDI',
        PAPER : "Papier",
        EMAIL : "Email",
        CHORUS : "Chorus",
        PORTAL : "Portail",
        PDP : "PDP",
        PPF : "PPF"
    }
    return status[key]
}
export const getProfileWithKey = (key) => {
    const status = {
        ADMIN : 'Administrateur portail',
        REJECT_MANAGER : "Gestionnaire conformité",
        ACCOUNTING : "Comptable",
        ACCOUNTING_TLV : "Comptable TLV",
        BUYER : "Gestionnaire Achats",
        SUPPLIER : "Fournisseur"
    }
    return status[key]
}
export const getRoleWithKey = (key) => {
    const roles = {
        admin : 'Administrateur',
        standard : "Standard"
    }
    return roles[key]
}
export const getLastActionWithKey = (key) => {
    const lastAction = {
        ACCOUNTING : 'Comptable',
        ACCOUNTING_TLV : 'Comptable TLV',
        SUPPLIER : "Fournisseur",
        REJECT_MANAGER : "Gestionnaire conformité",
        BUYER : "Gestionnaire Achats",
        RVCA : "Retour en vidéo-codage"
    }
    return lastAction[key]
}

// export const getThirtyNextDays = () => {
//     const today = new Date();
//     let nextDays = [today.getTime()]
//     for (let i = 1; i < 30 ; i++) {
//         let nextDay = today.setDate(today.getDate() + 1);
//         nextDays.push(nextDay)
//     }
//     return nextDays;
// }

export const getThirtyNextDays = (period) => {
    const d = new Date(new Date().getFullYear(), 0, 1);
    console .log(d.getDate()+35);   
    var date1 = new Date(d); 
    var date2 = new Date(); 
    var Diff_temps = date2.getTime() - date1.getTime(); 
    var Diff_jours = Diff_temps / (1000 * 3600 * 24);
    console.log(Math.round(Diff_jours));
    const today = new Date();

    switch(period){
        case 'y':
            let nextDays = [d.getTime()]
            for (let i = 1; i < Math.round(Diff_jours) ; i++) {
                let nextDay = d.setDate(d.getDate() + 1);
                nextDays.push(nextDay)
            }
            return nextDays;
        case 'm' :
             let nextDa = [today.getTime()]
            for (let i = 1; i < 30 ; i++) {
                let nextDay = today.setDate(today.getDate() + 1);
                nextDa.push(nextDay)
            }
            return nextDa; 

            case null :
             let nextD = [today.getTime()]
            for (let i = 1; i < 30 ; i++) {
                let nextDay = today.setDate(today.getDate() + 1);
                nextD.push(nextDay)
            }
            return nextD; 

            case "d" :
             let next = [today.getTime()]
            for (let i = 1; i < 2 ; i++) {
                let nextDay = today.setDate(today.getDate() + 1);
                next.push(nextDay)
            }
            return next; 
    }
    
}


export const getTimeDate = (date) => {
    let newDate = new Date(date);
    let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
    let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
    return hour + ":" + minutes
}

export const formatNumber = (number) => {
    return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 2 }).format(number).replaceAll('.',' ')
}

export const verificationData=(res,entity)=>{
    if(res.unitesLegales[0].denominationUniteLegale==entity.name ){
        return true
    }

}

const updateDate=(a)=>{
    let d = new Date(+a);
  let dateLimit= d.setDate(d.getDate() -15)
  let dateLimitTime=d.getTime();
  return dateLimitTime;

}
export const validatDocs = (list,data) => {
    let newDate = new Date();
   var time=newDate.getTime();
  
 
    var validDoc=  list?.map(function(e) {
        return  {...e,valide: data.some(row => row.type === e.value && row.expirationDate> time && row.valid=="1")}
       })

      var validDoc2=validDoc?.map(function(e) {
        return  {...e,alert: data.some(row => row.type === e.value &&  time> updateDate(row.expirationDate)&& row.valid=="1")}
       })
       var validDoc3=validDoc2?.map(function(e) {
        return  {...e,attent: data.some(row => row.type === e.value &&  row.valid==null && row.invalidate==null)}
       })
 return validDoc3
}
export const  getNotyfObject = () => {
    return notyf
}
export const  getNotyfObject10s = () => {
    return notyf10s
}
export const getLabelWithKey = (key) => {
    const status = {
        FACTURE : "Facture",
        RELANCE : "Relance",
        REJETS : "Rejet",
        PWD_RESET : "Réinitialisation de mot de passe",
        CREATION : "Création",
        USER : "Utilisateur",
        INVOICE: "Facture",
        REJETS: "Rejets",
        Err01:"Err01",
        Err02:"Err02",
        Err03:"Err03",
        Err03a:"Err03a",
        Err03c:"Err03c",
        Err04:"Err04",
        Err04a:"Err04a",
        Err04b:"Err04b",
        Err05:"Err05",
        Err05a:"Err05a",
        Err05b:"Err05b",
        Err05c:"Err05c",
        Err06:"Err06",
        Err07:"Err07",
        Err07a:"Err07a",
        Err08:"Err08",
        Err08a:"Err08a",
        Err09:"Err09",
        Err10:"Err10",
        Err10a:"Err10a",
        Err11:"Err11",
        Err12:"Err12",
        Err12a:"Err12a",
        Err51:"Err51",
        Err52:"Err52",
        PWD_REMINDER : "Rappel de changement de mot de passe",
        FUTURE_INVOICE_REJECTED : "Facture refusée dont la date est postérieure de 20 jours",
        commentaire:"Commentaire",
        COMMENT:"Commentaire",
        CAMPAIGN:"Campagne",
        COM:"Communication",
        ERROR:"ERREUR",
        PROGRESS:"EN COURS",
        WAITING:"ATTENTE",
        DONE:"FAIT",
        IMPORT: "system",
        CODA: "codaFeedback",
        ACCOUNTING_AUDIT:"Circularisation",
        ACT_AUDIT:"Campagne de circularisation",
        ACT_AUDIT_REMINDER:"Campagne de circularisation - Relance",
        PAPER_ALERT:"Alerte aux fournisseurs papier"
    }
    return status[key]
}

export const getEventTooltipWithKey = (key) => {
    const tooltip = { 
        PWD_RESET : "Mail envoyé lors d'une demande de réinitialisation de mot de passe",
        CREATION : "Mail envoyé lors de la création d'un compte utilisateur", 
        Err01:"Mail envoyé lors d'un rejet de type Err01",
        Err02:"Mail envoyé lors d'un rejet de type Err02",
        Err03:"Mail envoyé lors d'un rejet de type Err03",
        Err03a:"Mail envoyé lors d'un rejet de type Err03a",
        Err03c:"Mail envoyé lors d'un rejet de type Err03c",
        Err04:"Mail envoyé lors d'un rejet de type Err04",
        Err04a:"Mail envoyé lors d'un rejet de type Err04a", 
        Err04b:"Mail envoyé lors d'un rejet de type Err04b", 
        Err05:"Mail envoyé lors d'un rejet de type Err05",
        Err05a:"Mail envoyé lorsqu'un avoir ne comporte pas de numéro de facture originelle",
        Err05b:"Mail envoyé lorsqu'un avoir comporte un numéro de facture inexistant dans Transparence",
        Err05c:"Mail envoyé lorsqu'un avoir comporte le numéro d'une facture sans litige",
        Err06:"Mail envoyé lors d'un rejet de type Err06",
        Err07:"Mail envoyé lors d'un rejet de type Err07",
        Err07a:"Mail envoyé lors d'un rejet de type Err07a",
        Err08:"Mail envoyé lors d'un rejet de type Err08",
        Err08a:"Mail envoyé lors d'un rejet de type Err08a",
        Err09:"Mail envoyé lors d'un rejet de type Err09",
        Err10:"Mail envoyé lors d'un rejet de type Err10",
        Err10a:"Mail envoyé lors d'un rejet de type Err10a",
        Err11:"Mail envoyé lors d'un rejet de type Err11",
        Err12:"Mail envoyé lors d'un rejet de type Err12", 
        Err12a:"Mail envoyé lors d'un rejet de type Err12a", 
        COMMENT:"Mail envoyé lors de l'ajout d'un commentaire sur une facture",
        CAMPAIGN:"Mail envoyé lors du début d'une campagne",
        PWD_REMINDER:"Mail envoyé à un utilisateur 15 jours avant la date d'expiration de son mot de passe pour lui rappeler de le changer",
        FUTURE_INVOICE_REJECTED:"Mail envoyé à un fournisseur lui indiquant que sa facture a été refusée car sa date est postérieure de 20 jours à la date de réception par Transparence"
    }
    return tooltip[key]
}

export const getImportCategoryWithKey = (key) => {
    const importCategory = {
        mailrecu : "Mails recus",
        rejetmail : "Mails rejetés renvoyés sur retour_facture@transdev.fr",
        totalmail : "Total mails traités",
        facturemail : "Factures mail",
        facturepapier : "Factures papier",
        sommerecues : "Total factures reçues",
        facturesTraitees : "Factures traitées",
        codeerr01 : "err01",
        codeerr02 : "err02",
        codeerr03 : "err03",
        codeerr04 : "err04",
        codeerr05 : "err05",
        codeerr06 : "err06",
        codeerr07 : "err07",
        codeerr08 : "err08",
        codeerr09 : "err09",
        codeerr10 : "err10",
        codeerr11 : "err11",
        codeerr12 : "err12",
        sommeerreurs : "Total factures en erreur",
        factureenvoyee : "Factures envoyées à Transdev",
        factureenerreuratraiterpartransdev : "Factures en erreur à traiter par Transdev",
        factureenerreurrenvoyee : "Factures en erreur renvoyées aux fournisseurs",
        HSpapier : "Hors scope papier reçus non numérisés"
    }
    return importCategory[key]
}

export const getErrorLabel = (type) => {
    switch (type) {
        case "err00":
            return "Erreur vidéocodage";
        case "err01":
            return "Document hors scope";
        case "err02":
            return "Fournisseur inconnu";
        case "err03":
            return "Raison sociale incorrecte";
        case "err04":
            return "Numéro de commande manquant";
        case "err04a":
            return "N° commande incorrect";
        case "err04b":
            return "Raison sociale non conforme au PO";
        case "err05":
            return "Mentions légales obligatoires manquantes";
        case "err06":
            return "Facture illisible ou incomplète";
        case "err07":
            return "Facture non équilibrée";
        case "err08":
            return "Etiquette TRS absente";
        case "err09":
            return "Entité hors scope";
        case "err10":
            return "Factures multiples dans un PDF";
        case "err11":
            return "Erreur autre (exemple : erreurs multiples)";
        case "err12":
            return "Problème de TVA intracommunautaire";
        case "err51":
            return "Doublon";
        case "err52":
            return "Faux doublon";
        case "err99":
            return "Corbeille";
        default:
            return type;
    }
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    return isValid;
}

export const removeObject = (array, object) => {
	if (array.indexOf(object) !== -1)
    array.splice(array.indexOf(object), 1);
};

export const tagsRandom = (value) => {
    // console.log("value in tagsRandom utility")
    // console.log(value)

    if(value?.includes("[DOCNUMBER]"))
        value = value?.replace("[DOCNUMBER]", "F"+Math.floor(Math.random() * 999999))

    if(value?.includes("[TTC]"))
        value = value.replace("[TTC]", "15023,00")

    if(value?.includes("[HT]"))
        value = value.replace("[HT]", "17902,00")   

    if(value?.includes("[FICHIERJOINT]"))
        value = value.replace("[FICHIERJOINT]", "Document.pdf",)  
    
    if(value?.includes("[DEVISE]"))
        value = value.replace("[DEVISE]", "Document.pdf") 
    
    if(value?.includes("[TOKEN]"))
        value = value?.replace("[TOKEN]", "&789845oigr")
    
    if(value?.includes("[CREATION]"))
        value = value.replace("[CREATION]", "F")
    
    // console.log("after checking includes ")
    // console.log(value)

    return value;
}

export const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export const isToday = (date) => {
    return isSameDay(date, new Date());
}

export const isYesterday = date => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return isSameDay(date, yesterday);
}

export const uniqueObjsArrayByUid = (arrayToFilter) => {
    const seen = new Set();
    const filteredArr = arrayToFilter.filter(el => {
        const duplicate = seen.has(el.uid);
        seen.add(el.uid);
        return !duplicate;
    });
    return filteredArr
}

export const  uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const ucFirst = (toFormat) => {
    return toFormat.charAt(0).toUpperCase() + toFormat.slice(1);
}

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const convertFileToBase64 = (file) => new Promise (resolve => {
    let baseUrl = "";
    let reader = new FileReader();
    //convert the file tobase64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
        //console.log('reader____', reader);
        baseUrl = reader.result;
        //console.log("baseUrl", baseUrl);
        resolve(baseUrl);
    };
    reader.onerror = (err) => console.log(err)
})