import React, { useMemo, useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { AppBody, AppContainer, AppContent,AppWrapper } from '../../../styles/App.styled';
import { Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Menu from '../../Menu/Menu'
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielFournisseur from '../../ReferentielFournisseur/ReferentielFournisseur';
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import Documentation from '../../Documentation/Documentation';
import NewDocumentation from '../../NewDocumentation/NewDocumentation';
import UploadInvoice from '../../UploadInvoice/UploadInvoice';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import Extraction from '../../Extraction/Extraction';
import ExtractionForm from '../../ExtractionForm/ExtractionForm';
import Campaigns from '../../Campaigns/Campaigns';
import CampaignForm from '../../CampaignForm/CampaignForm';
import EntitiesManager from '../../EntitiesManager/EntitiesManager';
import Regroupement from '../../EntitiesManager/Regroupement';
import NewRegroupement from '../../../components/Regroupement/NewGrouping/NewGroupingForm';
import GroupingDetail from '../../../components/Regroupement/GroupingDetail/GroupingDetail';

import EmailsSettings from '../../EmailsSettings/EmailsSettings';
import EmailsSettingsDetail from '../../EmailsSettingsDetail/EmailsSettingsDetail';
import Settings from '../../Settings/Settings';
import Home from '../../Home/Home';
import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DescriptionIcon from '@mui/icons-material/Description';
import TimerIcon from '@mui/icons-material/Timer';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import WarningIcon from '@mui/icons-material/Warning';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from 'react-i18next';
import UploadDoc from '../../UploadDocument/UploadDoc';
import Reporting from '../../Ereporting/Ereporting'
import EreportingInvoices from '../../EreportingInvoices/EreportingInvoices';
import BusinessIcon from '@mui/icons-material/Business';
import RejectTracking from '../../RejectTracking/RejectTracking';
import Reject9xTracking from '../../Reject9xTracking/Reject9xTracking';
import Reject51Tracking from '../../Reject51Tracking/Reject51Tracking';
import RejectWaitingForCreditNoteTracking from '../../RejectWaitingForCreditNoteTracking/RejectWaitingForCreditNoteTracking';
import DocumentationView from '../../../components/Documentation/DocumentationVisualisation/DocumentationView'
import EmailTemplate from '../../../components/EmailsSettings/EmailTemplate/EmailTemplate';
import FAQ from '../../FAQ/FAQ';
import UsersManagement from '../../UsersManagement/UsersManagement';
import NonEngagingCorrelationTable from '../../NonEngagingCorrelationTable/NonEngagingCorrelationTable';
import UnknownInvoices from '../../UnknownInvoices/UnknownInvoices';
import AccountingAuditTable from '../../AccountingAuditTable/AccountingAuditTable';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import InfoBands from '../../InfoBands/InfoBands';
import NewInfoBands from '../../InfoBands/NewInfoband/NewInfoband';
import { BtnOutlineAction } from '../../../styles/Common';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import AuditTrail from '../../AuditTrail/AuditTrail';
import AuditTrailForm from '../../AuditTrailForm/AuditTrailForm';
import InvoicesPeriodQuery from '../../InvoicesPeriodQuery/InvoicesPeriodQuery';

function OwnerRoot({userProfile, userLevel, statuses, getInvoiceCounters, counters}) {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState("Accueil");
    const [overflowShow, setOverflowShow] = useState(false);
    const menuItems = useMemo(() => {
        const tempItems = [
            {isLink: true, key: "home", label: 'Accueil', pathname: '/', icon: <HomeIcon />}
        ];

        if (userProfile === "ADMIN" || userProfile === "REJECT_MANAGER")
            tempItems.push({isLink: false, label: 'Fournisseur', key: 'supplier', icon: <MoveToInboxIcon />, subMenu: [
                {path: '/referentielFournisseur', label: 'Export AGSRM'},
                {path: '/contacts', label: 'Contacts'},
                userProfile === "ADMIN" && {path: '/invoicesPeriodQuery', label: t('navbar:invoiceTracking', 'Suivi des factures'), tooltip: "Total des factures hors err99, err01, sans date de facture et post-datées"},
                userProfile === "ADMIN" && {path: '/litigation', label: 'Litiges'},
                userProfile === "ADMIN" && {path: '/noOrder', label: 'Factures sans commande'}
            ].filter(m => m)});
        if (userProfile === "BUYER" || userProfile === "ACCOUNTING" || userProfile === "ACCOUNTING_TLV" || userProfile === "REJECT_MANAGER") {
            tempItems.push({isLink: true, key: "invoicesPeriodQuery", pathname: '/invoicesPeriodQuery', label: t('navbar:invoiceTracking', 'Suivi des factures'), icon: <DescriptionIcon />, tooltip: "Total des factures hors err99, err01, sans date de facture et post-datées"});
            userProfile !== "REJECT_MANAGER" && tempItems.push({isLink: true, key: "contactsList", pathname: '/contacts', label: 'Contacts', icon: <BusinessIcon />});
            userProfile !== "REJECT_MANAGER" && tempItems.push({isLink: true, key: "litigation", pathname: '/litigation', label: 'Litiges', icon: <WarningIcon />, counter: counters.litigation});
            tempItems.push({isLink: true, key: "noOrder", pathname: '/noOrder', label: 'Factures sans commande', icon: <PhoneDisabledIcon />});
        }
        tempItems.push({isLink: true, key: "futureInvoices", pathname: '/futureInvoices', label: 'Factures post-datées', icon: <TimerIcon />, counter: counters.postdated});
        
        if (userProfile === "ADMIN" || userProfile === "REJECT_MANAGER" && userLevel === "admin")
            tempItems.push({isLink: false, label: 'Remontée de statut de factures inconnues', key: 'unknownInvoices', icon: <HelpCenterIcon />, counter: counters.unknown, subMenu: [
                {path: '/unknownInvoices', label: 'En cours', show: true},
                {path: '/unknownInvoicesArchives', label: 'Archives'}
            ]});

        if (userProfile === "REJECT_MANAGER" || userProfile === "ADMIN") {
            tempItems.push({isLink: false, label: 'Gestion des rejets', key: 'rejects', icon: <FlipCameraAndroidIcon />, counter: counters.reject, subMenu: [
                {path: '/rejectTracking', label: 'Rejets à traiter', show: true},
                {path: '/rejectPending', label: 'Rejets en cours'},
                {path: '/rejectArchives', label: 'Archives'},
                {path: '/reject51', label: 'Vrais doublons'},
                {path: '/rejectWaitingForCreditNote', label: 'En attente d\'avoir'},
                {path: '/rejectWaitingForCreditNoteTreated', label: 'En attente d\'avoir clôturés'},
                {path: '/reject99', label: 'Rejets 99'}
            ]});
        }

        // if (userProfile === "REJECT_MANAGER" || userProfile === "ADMIN") {
        //     tempItems.push({isLink: false, label: "Rejets 9X", key: 'rejects9x', icon: <FlipCameraAndroidIcon />, counter: counters.reject9x, subMenu: [
        //         {path: '/reject9xPending', label: 'Rejets 9x en cours'},
        //         {path: '/reject9xArchives', label: 'Archives'}
        //     ]});
        // }
        if (userProfile === "REJECT_MANAGER" || userProfile === "ADMIN" || userProfile === "BUYER")
            tempItems.push({isLink: false, label: "Gestion refus d'entrée CODA", key: 'codaRefused', icon: <RemoveCircleIcon />,counter: counters.codaRefused, subMenu: [
                {path: '/codaRefused', label: 'Refus d’entrée CODA en cours', show: true},
                {path: '/codaRefusedArchives', label: 'Archives'}
            ]});
        
        if (userProfile === "ADMIN" || userProfile === "ACCOUNTING" || userProfile === "ACCOUNTING_TLV")
            tempItems.push({isLink: true, key: "accountingAudit", pathname: '/accountingAudit', label: 'Circularisation', icon: <SupervisedUserCircleIcon />});

        if (userLevel === "admin" && (userProfile === "ADMIN" || userProfile === "REJECT_MANAGER"))
            tempItems.push({isLink: true, key: "auditTrail", pathname: '/auditTrail', label: "Piste d'audit fiable", icon: <CallSplitIcon />});

        tempItems.push({isLink: true, key: "documentation", label: 'Documentation', pathname: '/documentation', icon: <ArticleIcon />});
        if ((userProfile === "ADMIN")  )
            tempItems.push({isLink: false, label: 'Communication', key: 'communication', icon: <ChatBubbleIcon />, subMenu: [
                {path: '/suiviCampagnes', label: t('navbar:suiviCampagnes', 'Suivi des campagnes')},
                {path: '/infobands', label: t('navbar:infobands', 'Bandeaux informatifs')}
            ]});

        tempItems.push({isLink: true, key: "extraction", label: 'Extraction', pathname: '/extraction', icon: <SaveIcon />});

        tempItems.push({isLink: true, key: "faq", pathname: '/FAQ', label: "FAQ", icon: <HelpOutlineIcon />})

        if (userProfile === "REJECT_MANAGER")
            tempItems.push({isLink: true, key: "entitiesList", pathname: '/entitiesManager', label: "Entités P2P", icon: <BusinessIcon />});

        if (userLevel === "admin" 
            // && userProfile !== "REJECT_MANAGER"
            ){
            if( ["ACCOUNTING","ACCOUNTING_TLV","BUYER", "REJECT_MANAGER"].includes(userProfile)){
     
                tempItems.push({isLink: false, label: 'Administration', key: 'administration', icon: <AdminPanelSettingsIcon />, subMenu: [
                    userProfile !== "REJECT_MANAGER" && {path: '/entitiesManager', label: t('navbar:entitiesManager', 'Gestion des entités')},
                    {path: '/grouping', label: t('menu:userMan', 'Gestion des regroupements')},
                    {path: '/usersManagement', label: t('menu:userManagement', 'Gestion des utilisateurs')},
                    userProfile === "REJECT_MANAGER" && {path: '/nonEngagingCorrelationTable', label: "Table de correspondance flux non engageables"},
                    // {path: '/emailsContentSettings', label: t('navbar:emails', 'Emails')},
                    // {path: '/emailsSettings', label: t('navbar:emailsSettings', 'Configuration envoi mail')},
                    // {path: '/configurationMail', label: t("navbar:ConfigurationMail", "Configuration mail")}
                ].filter(m => m)})
            }else {
                // tempItems.push({isLink: true, pathname: '/FAQ', label: "FAQ", icon: <HelpOutlineIcon />})
                tempItems.push({isLink: false, label: 'Administration', key: 'administration', icon: <AdminPanelSettingsIcon />, subMenu: [
                    {path: '/entitiesManager', label: t('navbar:entitiesManager', 'Gestion des entités')},
                    {path: '/grouping', label: t('menu:userMan', 'Gestion des regroupements')},
                    {path: '/usersManagement', label: t('menu:userManagement', 'Gestion des utilisateurs')},
                    {path: '/emailsContentSettings', label: t('navbar:emails', 'Gestion des mails')},
                    {path: '/nonEngagingCorrelationTable', label: "Table de correspondance flux non engageables"},
                    // {path: '/emailsSettings', label: t('navbar:emailsSettings', 'Configuration technique')}
                    // {path: '/configurationMail', label: t("navbar:ConfigurationMail", "Configuration mail")}
                ]})
            }
        }else{
            if( ["ACCOUNTING_TLV"].includes(userProfile)){
                tempItems.push({isLink: false, label: 'Administration', key: 'administration', icon: <AdminPanelSettingsIcon />, subMenu: [                   
                    {path: '/nonEngagingCorrelationTable', label: "Table de correspondance flux non engageables"}                   
                ]})
            } 
        }
        
        return tempItems;
    }, [t,userProfile, userLevel, counters])

    useEffect(() => {
        getInvoiceCounters()
    }, [userProfile, userLevel])

    useEffect(() => {
        switch (location.pathname) {
            case "/editPassword":
                setPageTitle("Changement de mot de passe");
                break;
            case "/newGrouping":
                setPageTitle("Nouveau groupement");
                break;
            case "/referentielFournisseur":
                setPageTitle("Export AGSRM");
                break;
            case "/reporting":
                setPageTitle("E-Reporting");
                break;
            case "/userForm":
                setPageTitle("Création d'utilisateur");
                break;
            case "/usersManagement":
                setPageTitle("Contacts");
                break;
            case "/usersManagement":
                setPageTitle("Contacts");
                break;
            case "/invoicesPeriodQuery":
            case "/fournisseurInvoices":
                setPageTitle("Suivi des factures");
                break;
            case "/futureInvoices":
                setPageTitle("Factures post-datées");
                break;
            case "/unknownInvoices":
                setPageTitle("Remontée de statut de factures inconnues");
                break;
            case "/unknownInvoicesArchives":
                setPageTitle("Remontée de statut de factures inconnues - Archives");
                break;
            case "/accountingAudit":
                setPageTitle("Circularisation");
                break;
            case "/litigation":
                setPageTitle("Litiges");
                break;
            case "/codaRefused":
                setPageTitle("Refus d’entrée CODA en cours ");
                break;
                case "/codaRefusedArchives":
                    setPageTitle("Refus d’entrée CODA archives  ");
                    break;
                
            case "/documentation":
                setPageTitle("Documentation");
                break;
            case "/addDocumentation":
                setPageTitle("Ajout de documentation");
                break;
            case "/extraction":
                setPageTitle("Extraction");
                break;
            case "/extractionForm":
                setPageTitle("Nouvelle demande d'extraction");
                break;
            case "/suiviCampagnes":
                setPageTitle("Campagnes");
                break;
            case "/infobands":
                setPageTitle("Bandeaux d'information");
                break;
            case "/newCampaign":
                setPageTitle("Création de campagne");
                break;
            case "/entitiesManager":
                setPageTitle("Gestion des entités");
                break;
            case "/emailsContentSettings":
                setPageTitle("Édition des modèles de mail");
                break;
            case "/emailsSettings":
                setPageTitle("Configuration de l'envoi des mails");
                break;
            case "/embarquementSupplier":
                setPageTitle("Embarquement fournisseur");
                break;
            case "/rejectTracking":
                setOverflowShow(true);
                setPageTitle("Rejets à traiter");
                break;
            case "/rejectPending":
                setPageTitle("Rejets en cours");
                setOverflowShow(false);
                break;
            case "/rejectArchives":
                setPageTitle("Archives de rejets");
                setOverflowShow(false);
                break;
            case "/reject51":
                setOverflowShow(true);
                setPageTitle("Rejets 51");
                break;
            case "/rejectWaitingForCreditNote":
                setOverflowShow(true);
                setPageTitle("Rejets en attente d'avoir");
                break;
            case "/rejectWaitingForCreditNoteTreated":
                setOverflowShow(true);
                setPageTitle("Rejets en attente d'avoir clôturés");
                break;
            case "/reject99":
                setPageTitle("Rejets 99");
                setOverflowShow(false);
                break;
            case "/emailsTemplate":
                setPageTitle("Modèle de mail");
                break;
            case "/grouping":
                setPageTitle("Gestion des regroupements");
                break;
            case "/FAQ":
                setPageTitle("Foire aux questions");
                break;
            case "/configurationMail":
                setPageTitle("Configuration des emails par défaut");
                break;
            case "/DocumentationView":
                setPageTitle("Visualisation documentation");
                break;
            case "/emailsContentSettingsDetail":
                setPageTitle("Edition mail");
                break;
            case "/contacts":
                setPageTitle("Contacts fournisseurs");
                break;
            case "/nonEngagingCorrelationTable":
                setPageTitle("Table de correspondance flux non engageables");
                break;
            case "/auditTrail":
                setPageTitle("Piste d'audit fiable");
                break;
            case "/auditTrailForm":
                setPageTitle("Formulaire de piste d'audit fiable");
                break;
            case "/noOrder":
                setPageTitle("Factures sans commande");
                break;
                
            default:
                if (location.pathname?.includes("/entity/")) {
                    const params = new URLSearchParams(location.search); 
                    switch (params.get("type")) {
                        case "supplier":
                            setPageTitle("Consultation fournisseur");
                            break;
                        case "owner":
                        default:
                            setPageTitle("Consultation d'entité");
                    }
                }
                else if (location.pathname?.includes("/userForm/"))
                    setPageTitle("Édition d'utilisateur");
                else if (location.pathname?.includes("/groupingDetail/"))
                    setPageTitle("Édition de groupement");
                else if (location.pathname?.includes("/invoiceDetail/"))
                    setPageTitle("Détails facture");
                else if (location.pathname?.includes("/extractionForm/"))
                    setPageTitle("Demande d'extraction");
                else if (location.pathname?.includes("/newCampaign/"))
                    setPageTitle("Édition de campagne");
                else
                    setPageTitle("Accueil");
        }
    }, [location,overflowShow])

  return (
    <>
        <AppContainer>
            <Menu items={menuItems} />
            <AppBody style={{flexDirection:"column",margin:""}} >
                <Header pageTitle={pageTitle}/> 
                <AppWrapper overflowShow={overflowShow}>
                    <AppContent>
                        {location.pathname !== "/" && 
                        <BtnOutlineAction
                            border="red"
                            color="red"
                            style={{position: "absolute", zIndex:100, marginLeft: 0}}
                            onClick={() => navigate(-1) }>
                            <span>Retour</span>
                        </BtnOutlineAction>}
                    <Routes>
                    
                        <Route path="/editPassword" element={<EditPassword />}/>
                        <Route path="/referentielFournisseur" element={<ReferentielFournisseur entityFilter={"supplier"} />} />
                        <Route path="/reporting" element={<Reporting entityFilter={"supplier"} />} />
                        {/* <Route path="/entity" element={<Entity newAccountRequest={false} />} /> */}
                        <Route path="/entity/:uid" element={<Entity newAccountRequest={false} />} />
                        <Route path="/userForm/:uid" element={<UserForm />} />
                        <Route path="/userForm" element={<UserForm />} />
                        <Route path="/entity" element={<Entity newAccountRequest={false} />} />
                        <Route path="/usersManagement" element={<UsersManagement entityFilter={"owner"} />} />
                        <Route path="/contacts" element={<UsersManagement entityFilter={"supplier"}isContacts={true} />} />
                        
                        {/* <Route path="/referentielClient" element={ <ReferentielClient entityFilter={"client"} />}/>
                        <Route path="/clientInvoices" element={ <SuiviInvoices entityFilter={"client"} />}/> */}
                        <Route path="/invoicesPeriodQuery" element={<InvoicesPeriodQuery />}/>
                        <Route path="/fournisseurInvoices" element={<SuiviInvoices entityFilter={"supplier"} statuses={statuses} />}/>
                        <Route path="/litigation" element={<SuiviInvoices entityFilter={"supplier"} statuses={statuses} isLitigation={true}/>}/>
                        <Route path="/futureInvoices" element={<SuiviInvoices entityFilter={"supplier"} statuses={statuses} isFuture={true} />}/>
                        <Route path="/unknownInvoices" element={<UnknownInvoices unknownInvoicesArchived={0} />}/>
                        <Route path="/unknownInvoicesArchives" element={<UnknownInvoices unknownInvoicesArchived={1} />}/>
                        <Route path="/eReportingInvoices/:uid" element={<EreportingInvoices />}/>
                        <Route path="/documentation" element={<Documentation/>} />
                        <Route path="/addDocumentation" element={<NewDocumentation/>} />
                        <Route path="/upload" element={<UploadInvoice />} />
                        <Route path="/UploadDoc/:uid" element={<UploadDoc />} />
                        <Route path="/invoiceDetail/:uid" element={<InvoiceDetail statuses={statuses} />} />
                        <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail statuses={statuses} />} />
                        <Route path="/extraction" element={<Extraction/>} />
                        <Route path="/extractionForm" element={<ExtractionForm/>} />
                        <Route path="/extractionForm/:uid" element={<ExtractionForm/>} />
                        <Route path="/suiviCampagnes" element={<Campaigns />} />
                        <Route path="/infobands" element={<InfoBands />} />
                        <Route path="/newInfoband/:uid" element={<NewInfoBands />} />
                        <Route path="/newCampaign" element={<CampaignForm />} />
                        <Route path="/newCampaign/:uid" element={<CampaignForm />} />
                        <Route path="/entitiesManager" element={<EntitiesManager />} />
                        <Route path="/emailsContentSettings" element={<EmailsSettings />}/>
                        <Route path="/emailsContentSettingsDetail/:uid" element={<EmailsSettingsDetail />}/>
                        <Route path="/addEmailsContentSettingsDetail/:eventId/" element={<EmailsSettingsDetail />}/>
                        {/* <Route path="/emailsSettings" element={<Settings />}/> */}
                        <Route path="/embarquementSupplier" element={<Campaigns />} />
                        {/* <Route path="/kpiClient" element={<ClientHome />} /> */}
                        <Route path="/embarquementClient" element={<Campaigns />} />
                        <Route path="/rejectTracking" element={<RejectTracking  statuses={statuses} page="new" showOnly="rest" />} />
                        <Route path="/rejectPending" element={<RejectTracking   statuses={statuses} page="pending" showOnly="rest" />} />
                        <Route path="/rejectArchives" element={<RejectTracking  statuses={statuses} page="archive" showOnly="rest" />} />
                        <Route path="/reject51" element={<Reject51Tracking  statuses={statuses} />} />
                        <Route path="/rejectWaitingForCreditNote" element={<RejectWaitingForCreditNoteTracking page="pending" showOnly="WFCN"/>} />
                        <Route path="/rejectWaitingForCreditNoteTreated" element={<RejectWaitingForCreditNoteTracking page="archive" showOnly="WFCN"/>} />
                        <Route path="/reject99" element={<Reject9xTracking statuses={statuses} showOnly="9x" />} />
                        <Route path="/codaRefused" element={<RejectTracking  codaRefusedArchived={0} statuses={statuses} page="codaRefused"/>}/>
                        <Route path="/codaRefusedArchives" element={<RejectTracking codaRefusedArchived={1}  statuses={statuses} page="codaRefused"/>}/>
                        
                        <Route path="/emailsTemplate" element={<EmailTemplate />} />
                        {/* <Route path="/configurationMail" element={<ConfigurationMail />} /> */}
                        <Route path="/DocumentationView" element={<DocumentationView />} />
                        <Route path="/newInfoband" element={<NewInfoBands />} />
                        <Route path="/grouping" element={<Regroupement />} />
                        <Route path="/newGrouping" element={<NewRegroupement />} />
                        <Route path="/groupingDetail/:uid" element={<GroupingDetail />} />
                        <Route path="/FAQ" element={<FAQ />} />
                        <Route path="/nonEngagingCorrelationTable" element={<NonEngagingCorrelationTable />} />
                        <Route path="/accountingAudit" element={<AccountingAuditTable />}/>
                        <Route path="/auditTrail" element={<AuditTrail />}/>
                        <Route path="/auditTrailForm" element={<AuditTrailForm/>} />
                        <Route path="/auditTrailForm/:uid" element={<AuditTrailForm/>} />
                        <Route path="/noOrder" element={<SuiviInvoices entityFilter={"supplier"} statuses={statuses} isNoOrder={true} />}/>
                        <Route path="/" element={<Home statuses={statuses} />} />
                    </Routes>
                </AppContent>
                </AppWrapper>
            </AppBody>
            </AppContainer>
        </>
      )
    }
    
    const mapStateToProps = (state) => ({
        counters: state.invoice.counters
    })
    const mapDispatchToProps = dispatch => ({
        getInvoiceCounters: () => dispatch(actions.invoiceGetCounters()) 
    })
    export default connect(mapStateToProps, mapDispatchToProps) (OwnerRoot)
