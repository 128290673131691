import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';

function TableFilters(props) {
    const {t} = useTranslation();
    const {filters, filtersChange, applyFilters, userType, regions, entityFilter} = props;

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        if (e.target.value?.length >= 3 || e.target.tagName !== "INPUT")
            applyFilters(newFilters);
        else if (e.target.value?.length < 3 && filters[key]?.length >= 3) {
            newFilters[key] = "";
            applyFilters(newFilters);
        }
    }
   
    return (
        <>
           <tr  style={{display: props.show ? 'table-row' : 'none',
                        position: "sticky",
                        top: "38px",
                        backgroundColor: "white",
                        zIndex: "99"}}>
                <TD ></TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("userList:first_name", "Prénom")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.first_name || ''} 
                            name="first_name" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("userList:last_name", "Nom")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.last_name || ''} 
                            name="last_name" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:login", "Email")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.login || ''} 
                            name="login" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.active || ''} 
                        name="active" 
                    >
                        <Option value="">Actif</Option>
                        <Option value="1">{t('tableHead:yes', 'Oui').toUpperCase()}</Option>
                        <Option value="0">{t('tableHead:no', 'Non').toUpperCase()}</Option>
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                {/* { entityFilter === 'owner' ? (

                <TD>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.type || ''} 
                        name="type" 
                    >
                        <Option value="">Type</Option>
                        {
                            userType === "owner"  &&
                            <Option value="owner">{t('internal', 'Interne').toUpperCase()}</Option>
                        }
                        {
                            (userType === "client") &&
                                <Option value="client">{t('tableFilters:client', "Client").toUpperCase()} </Option>
                        }
                        {
                            (userType === "supplier") &&
                                <Option value="supplier">{t('tableFilters:supplier', "Fournisseur").toUpperCase()} </Option>
                        }
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                ) : null
                } */}
                    <TD>
                        <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.level || ''} 
                            name="level" 
                        >
                            <Option value="">Rôle</Option>
                            <Option value="admin">{t('admin', "Admininistrateur")}</Option>
                            <Option value="standard">{t('standard', "Standard")}</Option>
                        </SelectFilter>
                        </FilterContainer>
                    </TD>
                    { entityFilter === 'owner' ? (
                <>
                    <TD>
                        <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.profile || ''} 
                            name="profile" 
                        >
                            <Option value="">Profil</Option>
                            <Option value="ADMIN">Administrateur portail</Option>
                            <Option value="REJECT_MANAGER">Gestionnaire conformité</Option>
                            <Option value="ACCOUNTING">Comptable</Option>
                            <Option value="ACCOUNTING_TLV">Comptable TLV</Option>
                            <Option value="BUYER">Gestionnaire Achats</Option>
                        </SelectFilter>
                        </FilterContainer>
                    </TD>
                </>) : null
                }
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:entities", "Nom Fournisseurs")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.entities || ''} 
                            name="entities" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                { entityFilter === 'supplier' ? (
                <TD >
                    <FilterContainer>
                        <InputFilter  
                            placeholder={t("userList:entitycodes", "Code Tiers")}  
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.entitycodes || ''} 
                            name="entitycodes" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                ) : null
                }
                { entityFilter === 'owner' ? (
                <TD >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.region || ''} 
                            name="region" 
                        >
                            <Option value="">Pôle/région</Option>
                            {regions?.map(r => <Option key={r.id} value={r.id}>{r.name}</Option>)}
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                ) : null
                }
                {userType === "owner" && <TD></TD>}
                
            </tr>
            <tr></tr>
        </>
    )
}

export default TableFilters
