import React, { useEffect, useState } from 'react'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CloseIcon from '@mui/icons-material/Close';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { useNavigate } from 'react-router-dom';
import { CTooltip } from '../../UI/CTooltip/CTooltip'
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import Axios from '../../../axios-proas'
import { getNotyfObject, getChannelWithKey, showColumn, getErrorLabel } from '../../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { TD, TR } from '../../../styles/Table.styled';
import { RejectNumber } from '../../../containers/RejectTracking/RejectTracking.styled';
import Select from 'react-select';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import SetErrorModal from '../../UI/SetErrorModal/SetErrorModal';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import { Button } from 'react-bootstrap';
import CustomConfirmModal from '../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal';
function TableBody(props) {
    const {data, formatDate, getInvoiceData,selectedRows, setSelectedRows,userProfile, userLevel, columns, isCodaRefused, codaRefusedArchived, refreshTable, page, userUid, setInvoiceData, setShowInformationModal, setRejectUnread} = props;
    
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const navigate = useNavigate();
    const [entitiesToPickFrom, setEntitiesToPickFrom] = useState([]);
    const [showSetErrorModal, setShowSetErrorModal] = useState(false);
    const [showEditStatusModal, setShowEditStatusModal] = useState(false);
    const [showRequestResurrectRejectModal, setShowRequestResurrectRejectModal] = useState(false);
    const [showResurrectRejectModal, setShowResurrectRejectModal] = useState(false);
    const [showReleaseRejectModal, setShowReleaseRejectModal] = useState(false);
    const [showUnarchiveCodaModal, setShowUnarchiveCodaModal] = useState(false);

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e, value) => {
        let id = e.target.name;
        setSelectedRows(id, value, page)
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 2 }).format(number).replaceAll('.',' ')
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        let seconds = newDate.getSeconds() < 10 ? "0" + newDate.getSeconds() : newDate.getSeconds()
        return hour + ":" + minutes + ":" + seconds
    }

    useEffect(() => {
        Axios.get('/entity//distinctOwners?orderBy=code').then(response => {
            setEntitiesToPickFrom(response.data);
        })
    }, [])

    const handleSetError = errorCode => {
        return new Promise((resolve, reject) => {
            Axios.put('reject/'+showSetErrorModal?.uid+'/errorCode', {newEntity: showSetErrorModal?.newEntity, errorCode: errorCode}).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès de l'attribution de code SU");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de l'attribution de code SU");
                    reject(false);
                }
            }).catch((error) => {
                console.error(error)
                notyf.error("Échec de l'attribution de code SU");
                reject(false);
            })
        })
    }
    
    const editStatusHandler = (uid, status) => {
        return new Promise((resolve, reject) => {
            Axios.put("reject/"+uid+"/status", {processingStatus: status, permanent: 1}).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès de l'archivage");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de l'archivage");
                    reject(false);
                }
            }).catch((error) => {
                console.error(error)
                notyf.error("Échec de l'archivage");
                reject(false);
            })
        })
    }
   
    const selectEntity = (event, row) => {
        if (event?.value) {
            const newRow = {...row, newEntity: event?.value};
            const index = data.findIndex(r => r.id === row.id);
            data[index] = newRow;
            setShowSetErrorModal(newRow)
        }
    }

    const renderLastActionAuthor = row => {
        switch (row.lastActionAuthor) {
            case "ADMIN":
                return <CTooltip title="Dernière action par administrateur portail"><SupervisorAccountIcon style={{color: "rgb(86,41,111)"}} /></CTooltip>;
            case "ACCOUNTING":
            case "ACCOUNTING_TLV":
                return <CTooltip title="Dernière action par comptable four"><AccountBalanceWalletIcon style={{color: "rgb(86,41,111)"}} /></CTooltip>;
            case "SUPPLIER":
                return <CTooltip title="Dernière action par fournisseur"><LocalShippingIcon style={{color: "rgb(86,41,111)"}}/></CTooltip>;
            case "REJECT_MANAGER":
                return <CTooltip title="Dernière action par gestionnaire conformité"><FlipCameraAndroidIcon style={{color: "rgb(86,41,111)"}}/></CTooltip>;
            case "BUYER":
                return <CTooltip title="Dernière action par gestionnaire Achats"><AssistantOutlinedIcon style={{color: "rgb(86,41,111)"}}/></CTooltip>;
            case "RVCA":
                return <CTooltip title="Retour en vidéo-codage"><AutorenewIcon style={{color: "rgb(86,41,111)"}}/></CTooltip>;
            case "DATA_CHANGE":
                return <CTooltip title="Changement de code SU et/ou de type d'erreur"><FilterNoneIcon style={{color: "rgb(86,41,111)"}}/></CTooltip>;
            default: return "";
        }
    }
    const clickedHandler2 = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        getInvoiceData(row.invoiceUid);
        setInvoiceData(row);
        setShowInformationModal(true);
    }

    const requestRejectResurrection = (row) => {
        return new Promise((resolve, reject) => {
            Axios.put("reject/"+row.uid+"/requestResurrection", {}).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès de la demande de réouverture du rejet");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de la demande de réouverture du rejet");
                    reject(false);
                }
            }).catch((error) => {
                console.error(error)
                notyf.error("Échec de la demande de réouverture du rejet");
                reject(false);
            })
        })
    }

    const confirmRejectResurrection = (row) => {
        return new Promise((resolve, reject) => {
            Axios.put("reject/"+row.uid+"/resurrect", 1).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès de la réouverture du rejet");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors de la réouverture du rejet");
                    reject(false);
                }
            }).catch((error) => {
                console.error(error)
                notyf.error("Échec de la réouverture du rejet");
                reject(false);
            })
        })
    }

    const refuseRejectResurrection = (row) => {
        return new Promise((resolve, reject) => {
            Axios.put("reject/"+row.uid+"/resurrect", 0).then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Succès du refus de réouverture du rejet");
                    resolve(true);
                } else {
                    notyf.error("Erreur lors du refus de réouverture du rejet");
                    reject(false);
                }
            }).catch((error) => {
                console.error(error)
                notyf.error("Échec du refus de réouverture du rejet");
                reject(false);
            })
        })
    }

    const releaseRejectHandler = (row) => {
        return new Promise((resolve, reject) => {
            Axios.put("reject/"+row.uid+"/release").then(response => {
                if (response.data.success) {
                    refreshTable();
                    notyf.success("Rejet libéré avec succès");
                    resolve(true);
                } else {
                    notyf.error("Impossible de libérer le rejet");
                    reject(false);
                }
            }).catch((error) => {
                console.error(error)
                notyf.error("Échec de la libération du rejet");
                reject(false);
            })
        })
    }
    
    const confirmUnarchivingCodaHandler = () => {
        return new Promise((resolve, reject) => {
            if (showUnarchiveCodaModal?.invoiceUid)
                Axios.put("invoice/"+showUnarchiveCodaModal?.invoiceUid+"/unarchiveCoda", 1).then(response => {
                    if (response.data.success) {
                        if (refreshTable)
                            refreshTable();
                        else
                            window.location.reload(false);
                        notyf.success("Succès du désarchivage du refus CODA");
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors du désarchivage du refus CODA");
                        reject(false);
                    }
                }).catch((error) => {
                    console.error(error)
                    notyf.error("Échec du désarchivage du refus CODA");
                    reject(false);
                })
            else
                reject("No uid given")
        })
    }
    
    const denyUnarchivingCodaHandler = () => {
        return new Promise((resolve, reject) => {
            if (showUnarchiveCodaModal?.invoiceUid)
                Axios.put("invoice/"+showUnarchiveCodaModal?.invoiceUid+"/unarchiveCoda", 0).then(response => {
                    if (response.data.success) {
                        if (refreshTable)
                            refreshTable();
                        else
                            window.location.reload(false);
                        notyf.success("Succès du refus de désarchivage du refus CODA");
                        resolve(true);
                    } else {
                        notyf.error("Erreur lors du refus de désarchivage du refus CODA");
                        reject(false);
                    }
                }).catch((error) => {
                    console.error(error)
                    notyf.error("Échec du refus de désarchivage du refus CODA");
                    reject(false);
                })
            else
                reject("No uid given")
        })
    }

    return (
        <>           
            {

                data.map((row,index) => (
                    <TR key={row?.id ? row?.id : row?.invoiceUid} onClick={() => navigate("/invoiceDetail/"+row.invoiceUid)} style={row?.managerUid && row?.managerUid !== userUid ? {color: "grey", fontStyle: "italic"} : {}}>
                        <TD >
                            <input type="checkbox" name={isCodaRefused ? row.invoiceUid:row.uid} onClick={e => e.stopPropagation()} onChange={(e) => selectRowHandler(e, row.rejectType === "err01" && e.target.checked ? "err01" : e.target.checked)} checked={isCodaRefused ? getSelectedRowValue(row.invoiceUid):getSelectedRowValue(row.uid)}  className="form-check-input" />
                        </TD>
                        {/*<CTooltip title={row.processingStatus ? row.processingStatus : "N/A"}>
                            <TD style={{display: !showColumn(columns,"processingStatus")&& 'none'}}>
                                {row.processingStatus ? row.processingStatus : "N/A"}
                            </TD>
                        </CTooltip> */}

                       

                        {!isCodaRefused && <>
                            <CTooltip title={getErrorLabel(row.rejectType) || ""}>
                                <TD style={{display: !showColumn(columns,"rejectType")&& 'none'}}>
                                    {row.rejectType}
                                </TD>
                            </CTooltip>

                            <CTooltip title={getErrorLabel(row.originalRejectType != row.rejectType ? row.originalRejectType : "") || "" }>
                                <TD style={{display: !showColumn(columns,"originalRejectType")&& 'none'}}>
                                    {row.originalRejectType != row.rejectType ? row.originalRejectType : "" }
                                </TD>
                            </CTooltip>

                            <TD style={{display: !showColumn(columns,"manager") && 'none'}}>
                                {userProfile === "REJECT_MANAGER" && row.managerUid && (userLevel === "admin" || userLevel === "standard" && row.managerUid === userUid) && <CTooltip title="Cliquez pour libérer le rejet">
                                    <CloseIcon style={{color: "red"}} onClick={e => {e.stopPropagation(); setShowReleaseRejectModal(row)}}/>
                                </CTooltip>}
                                <CTooltip title={row.managerLogin ? (row.managerLogin + (row.managerName ? " (" + row.managerName + ")" : "")) : ""}>
                                    <span>{row.managerLogin ? (row.managerLogin + (row.managerName ? " (" + row.managerName + ")" : "")) : ""}</span>
                                </CTooltip>
                            </TD>
                        </>}

                        <CTooltip title={formatDate(+row.archibaldDate) || ""}>
                            <TD style={{display: !showColumn(columns,"archibaldDate")&& 'none'}}>{formatDate(+row.archibaldDate)}</TD>
                        </CTooltip>
                        <CTooltip title={formatDate(+row.lastActionDate) || ""}>
                            <TD style={{display: !showColumn(columns,"lastActionDate") && 'none'}}>{formatDate(+row.lastActionDate)}</TD>
                        </CTooltip>
                        <TD style={{display: !showColumn(columns,"lastActionAuthor")&& 'none'}}>{row.action}
                            {renderLastActionAuthor(row)}
                        </TD>
                        <CTooltip title={getChannelWithKey(row.channel) || ""}>
                            <TD style={{display: !showColumn(columns,"channel")&& 'none'}}>{getChannelWithKey(row.channel)}</TD>
                        </CTooltip>
                        <CTooltip title={row.rejectType === "err01" && !row.suCode && userProfile === "REJECT_MANAGER" ? "Saisissez un code SU" : (row.suCode || "")}>
                            <TD style={{display: !showColumn(columns,"suCode") ? 'none' : (row.rejectType === "err01" && !row.suCode ? "inherit" : 'table-cell')}} onClick={e => e.stopPropagation()}>
                                {row.rejectType === "err01" && !row.suCode && userProfile === "REJECT_MANAGER" && page!='archive' ?
                                    <Select
                                        className='largeSelect'
                                        name="entitySelector"
                                        // value={row?.newEntityCode|| ""}
                                        onChange={e => selectEntity(e, row)}
                                        placeholder={"Saisissez un code SU"}
                                        noOptionsMessage={() => "Aucun résultat"}
                                        // onInputChange={input =>{console.log(row.entitiesToPickFrom?.map(e => {return {value: e.id, label : e.code + " (" + e.name + ")"}})); loadEntities(input, row)}}

                                        onSelect={e => selectEntity(e, row)}
                                        isRtl={false}
                                        // options={row.entitiesToPickFrom?.map(e => {return {value: e.id, label : e.code + " (" + e.name + ")"}}) || []}
                                        options={entitiesToPickFrom?.map(e => {return {value: e.id, label : e.code + " " + e.name}})}
                                    /> : row.suCode}
                            </TD>
                        </CTooltip>
                        <CTooltip title={row.ownerName || ""}>
                            <TD style={{display: !showColumn(columns,"ownerName")&& 'none'}}>
                            <span>{row.ownerName}</span>
                            </TD>
                        </CTooltip>
                        <CTooltip title={row.supplierCode || ""}>
                            <TD style={{display: !showColumn(columns,"supplierCode")&& 'none'}}>
                                {row.supplierCode}
                            </TD>
                        </CTooltip>
                        <CTooltip title={row.supplierName || ""}>
                            <TD style={{display: !showColumn(columns,"supplierName")&& 'none'}}>{row.supplierName}</TD>
                        </CTooltip>
                        <TD style={{display: !showColumn(columns,"number")&& 'none', cursor: "default"}} onClick={e => e.stopPropagation()}>
                            <RejectNumber style={{backgroundColor:"transparent"}}>
                                <CTooltip title={row.number || ""}><span>{row.number!="" ? row.number : "[vide]"}</span></CTooltip>
                                { page=='archive' ? (
                                        <>
                                            {userLevel === "admin" && userProfile === "REJECT_MANAGER" && !row.requestorUid && <CTooltip title="Rouvrir le rejet archivé"><AutorenewIcon style={{color:"red", cursor: "pointer"}} onClick={(e) => {e.stopPropagation(); e.preventDefault(); setShowRequestResurrectRejectModal(row)}} /></CTooltip>}
                                            {userLevel === "admin" && ["REJECT_MANAGER", "ADMIN"].includes(userProfile) && row.requestorUid && <CTooltip title="En attente de validation par admin portail">
                                                <HourglassFullIcon style={{color:"red", cursor: userProfile === "ADMIN" ? "pointer" : "default"}}
                                                 onClick={(e) => {e.stopPropagation(); e.preventDefault(); if(userProfile === "ADMIN") setShowResurrectRejectModal(row)}}/>
                                            </CTooltip>}
                                        </>
                                    ) : (isCodaRefused && codaRefusedArchived && row.unarchivingCodaRequestor && userLevel === "admin" && ["REJECT_MANAGER", "ADMIN"].includes(userProfile) ?
                                            <CTooltip title="En attente de validation par admin portail">
                                                <HourglassFullIcon style={{color:"red", cursor: userProfile === "ADMIN" ? "pointer" : "default"}}
                                                onClick={(e) => {e.stopPropagation(); e.preventDefault(); if(userProfile === "ADMIN") setShowUnarchiveCodaModal(row)}}/>
                                            </CTooltip>
                                        : <CTooltip title="Cliquez pour afficher les détails de la facture"><FeedOutlinedIcon style={{color:"red", cursor: "pointer"}}onClick={(e) => clickedHandler2(e, row)} /></CTooltip>
                                    )
                                }
                            </RejectNumber>
                        </TD>
                        <CTooltip title={t("invoiceList:" + (row?.documentType === "INV" ? "invoice" : (row?.documentType === "CRN" ? "creditNote" : "unknown"))) || ""}><TD style={{display: !showColumn(columns,"documentType") && 'none'}}>
                            {t("invoiceList:" + (row?.documentType === "INV" ? "invoice" : (row?.documentType === "CRN" ? "creditNote" : "unknown")))}
                        </TD></CTooltip>
                        <CTooltip title={formatNumber(row?.inclusiveTaxAmount) || ""}>
                            <TD style={{display: !showColumn(columns,"inclusiveTaxAmount") && 'none'}}>
                                {formatNumber(row?.inclusiveTaxAmount)}
                            </TD>
                        </CTooltip>
                        <CTooltip title={formatNumber(row?.exclusiveTaxAmount) || ""}>
                            <TD style={{display: !showColumn(columns,"exclusiveTaxAmount") && 'none'}}>
                                {formatNumber(row?.exclusiveTaxAmount)}
                            </TD>
                        </CTooltip>
                        <CTooltip title={row.orderNumber || ""}>
                            <TD style={{display: !showColumn(columns,"orderNumber")&& 'none'}}>
                                {row.orderNumber}
                            </TD>
                        </CTooltip>
                        <CTooltip title={formatDate(+row.issuingDate) || ""}>
                            <TD style={{display: !showColumn(columns,"issuingDate") && 'none'}}>{formatDate(+row.issuingDate)}</TD> 
                        </CTooltip>
                        {isCodaRefused && 
                            <CTooltip title={row.refusalDescription || ""}>
                                <TD style={{display: !showColumn(columns,"refusalDescription")&& 'none'}}>
                                    {row.refusalDescription}
                                </TD>
                            </CTooltip>}
                        <CTooltip title={row.compostingNumber || ""}>
                            <TD style={{display: !showColumn(columns,"compostingNumber")&& 'none'}}>
                                {row.compostingNumber}
                            </TD>
                        </CTooltip>
                        <TD style={{display: !showColumn(columns,"unreadMessages")&& 'none'}}>{
                            row.unreadMessages ? 
                                <CTooltip title={"Nouveau(x) commentaire(s) le "+ formatDate(+row?.unreadMessages) + " " + getTimeDate(+row?.unreadMessages)+" - Cliquez sur la facture pour marquer les commentaires comme lus"} className="tableActions__icon">
                                    <TextsmsIcon fontSize="small" style={{color: "#56296f", fontSize: "20px"}}/> 
                                </CTooltip>
                            : 
                                <CTooltip title={"Double-cliquez sur l'icône pour marquer les commentaires comme non-lus"} className="tableActions__icon">
                                    <ChatBubbleOutlineIcon fontSize="small" style={{color: "#56296f", fontSize: "20px"}} onClick={e => e.stopPropagation()} onDoubleClick={e => {setRejectUnread(row.invoiceUid)}} /> 
                                </CTooltip>
                        }</TD>
                        <CTooltip title={formatDate(+row.dueDate) || ""}>
                             <TD style={{display: !showColumn(columns,"dueDate")&& 'none'}}>{formatDate(+row.dueDate)}</TD>
                        </CTooltip>
                        <CTooltip title={row.ivmStatus || ""}>
                            <TD style={{display: !showColumn(columns,"ivmStatus")&& 'none'}}>{row.ivmStatus}</TD>
                        </CTooltip>
                        <CTooltip title={row.chronoCode || ""}>
                            <TD style={{display: !showColumn(columns,"chronoCode")&& 'none'}}>{row.chronoCode}</TD>
                        </CTooltip>
                    </TR>
                ))
                
            }   
			<SetErrorModal 
                show={showSetErrorModal}
                closeModal={() => setShowSetErrorModal(false)}
                okCallback={errorCode => handleSetError(errorCode)}
            />

            <CustomConfirmModal 
                show={showEditStatusModal}
                handleClose={() => setShowEditStatusModal(false)}
                text={`Êtes-vous sûr de vouloir archiver ce rejet ? Il sera considéré comme traité et ne sera pas envoyé à CODA`}
                okCallback={() => editStatusHandler(showEditStatusModal, "TREATED")}/>

            <CustomConfirmModal 
                show={showRequestResurrectRejectModal}
                handleClose={() => setShowRequestResurrectRejectModal(false)}
                text={`Êtes-vous sûr de vouloir rouvrir ce rejet ? Votre demande devra être approuvée par un administrateur portail`}
                okCallback={() => requestRejectResurrection(showRequestResurrectRejectModal)}/>
            <CustomConfirmModal 
                show={showResurrectRejectModal}
                handleClose={() => setShowResurrectRejectModal(false)}
                text={`Êtes-vous sûr de vouloir approuver la réouverture de ce rejet ? Il apparaitra dans les rejets en cours`}
                okCallback={() => confirmRejectResurrection(showResurrectRejectModal)}
                koCallback={() => refuseRejectResurrection(showResurrectRejectModal)}/>
            <CustomConfirmModal 
                show={showReleaseRejectModal}
                handleClose={() => setShowReleaseRejectModal(false)}
                text={`Êtes-vous sûr de vouloir libérer ce rejet `+(showReleaseRejectModal?.managerUid && showReleaseRejectModal?.managerUid !== userUid ? `de son responsable actuel ` : ``)+`?`}
                okCallback={() => releaseRejectHandler(showReleaseRejectModal)}/>
            <CustomConfirmModal 
                show={showUnarchiveCodaModal}
                handleClose={() => setShowUnarchiveCodaModal(false)}
                text={`Êtes-vous sûr de vouloir approuver le désarchivage de ce refus CODA ? Il apparaitra dans les refus d'entrée CODA en cours`}
                okCallback={confirmUnarchivingCodaHandler}
                koCallback={denyUnarchivingCodaHandler}/>
        </>
    )
}
const mapStateToProps = (state) => ({
    userUid: state.auth.userUid  ,
    loadingData: state.invoiceDetail.loadingData,
    invoiceError: state.invoiceDetail.invoiceError,
    userLevel: state.auth.userLevel,
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.rejectGetDocumentFile(uid)),
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    setRejectUnread: (invoiceUid) => dispatch(actions.rejectSetUnread(invoiceUid))   
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
