import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import GetAppIcon from '@mui/icons-material/GetApp';
import { THead, TH, THContainer} from '../../../styles/Table.styled'
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TableHead(props) {
    const {columns, reverse, setSortQuery, setReverse, getData, selectedAllRows, setSelectedAllRows, userProfile, filters} = props;
    const {t} = useTranslation();
    
    const location = useLocation();
    const columnClickHandler = (field) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getData(field,newReverse);
    }

    const selectAllRowsHandler = (e) => {
        setSelectedAllRows(!selectedAllRows);
    }

    return (
        <>
            <THead>
                    <tr>
                        <TH width="50px" scope="col">
                            <CTooltip title={t("invoiceList:selectAll", "Select all invoices")}>
                                <THContainer style={{justifyContent: "start"}}>
                                    <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
                                </THContainer>
                            </CTooltip>
                        </TH>
                       
                        {columns.filter(row=>row.show==true).map(column => (
                            <TH 
                                key={column.field} 
                                className={filters?.[location.pathname]?.[column.field] && 'filtered'}
                                scope="col" 
                                width={column.width}
                                id={column.field} 
                                onClick={() => columnClickHandler(column.field)}
                            >
                                <CTooltip title={column.title}>
                                    <THContainer>
                                        <span>
                                            {column.title}
                                        </span>
                                        <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                                        <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                                    </THContainer>  
                                </CTooltip>  
                            </TH>
                            
                        ))}
                        {!["ACCOUNTING","ACCOUNTING_TLV"].includes(userProfile) && <TH scope="col" width="60px">
                            <CTooltip title={t("invoiceList:downloadInvoice", "Download the invoice")}>
                                <THContainer style={{cursor: "default"}}>
                                        <GetAppIcon 
                                            style={{fontSize: "1.2rem", color: "white"}}
                                            //onClick={(e) => selectAllRowsHandler(e)} 
                                        />
                                </THContainer>
                            </CTooltip>
                        </TH>}
                    </tr>
            </THead>
        </>
    )
}

export default TableHead
