import styled from 'styled-components/macro';

export const CIUnlimitBtn = styled.button`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 1rem;
    /* padding: 0.5rem; */
    border: 1px solid ${({border, theme}) => border ? border : theme.colors.redLight};
    border-radius: 8px;
    color: ${({color, theme}) => color || theme.colors.redLight};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;

    & span {
        color: ${({color, theme}) => color || theme.colors.redLight};
    }

    &:hover {
        background-color: ${({color,theme}) =>color || theme.colors.redLight};
        color: #FFFFFF;
    }
`;
