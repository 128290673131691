import React from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, TD } from '../../../styles/Table.styled';
import {showColumn} from '../../../shared/utility'

function TableFilters(props) {
    const {showFilters, filters, filtersChange, applyFilters, columns} = props;
    const {t} = useTranslation();
    
    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        if (e.target.value?.length >= 3 || e.target.tagName !== "INPUT")
            applyFilters(newFilters);
        else if (e.target.value?.length < 3 && filters[key]?.length >= 3) {
            newFilters[key] = "";
            applyFilters(newFilters);
        }
    }

    return (
        <>
            <tr style={{display: showFilters ? 'table-row' : 'none',
                position: "sticky",
                top: "38px",
                backgroundColor: "white",
                zIndex: "99"}}>
                <TD></TD>
                <TD style={{display: !showColumn(columns, "suCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                        onChange={(e) => inputChangeHandler(e)}
                        placeholder="Code SU"
                        value={filters?.suCode || ''} 
                        name="suCode" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "supplierCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)}
                        placeholder="Code tiers"
                        value={filters?.supplierCode || ''} 
                        name="supplierCode" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "contractRef") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)} 
                        placeholder="Référence contrat"
                        value={filters?.contractRef || ''} 
                        name="contractRef" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element1") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.element1 || ''} 
                        placeholder="Élément 1"
                        name="element1" 
                        type="text" 
                        autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element2") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.element2 || ''} 
                            placeholder="Élément 2"
                            name="element2" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element3") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            placeholder="Élément 3"
                            value={filters?.element3 || ''} 
                            name="element3" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element4") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.element4 || ''} 
                            placeholder="Élément 4"
                            name="element4" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element4Percentage") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.element4Percentage || ''} 
                            placeholder="% élément 4"
                            name="element4Percentage" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element5") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            placeholder="Élément 5"
                            value={filters?.element5 || ''} 
                            name="element5" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "element6") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            placeholder="Élément 6"
                            value={filters?.element6 || ''} 
                            name="element6" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "contractTlvRef") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            placeholder="Num contrat TLV"
                            value={filters?.contractTlvRef || ''} 
                            name="contractTlvRef" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns, "parcRef") && 'none'}}>
                    <FilterContainer>
                        <InputFilter
                            onChange={(e) => inputChangeHandler(e)} 
                            placeholder="Num parc"
                            value={filters?.parcRef || ''} 
                            name="parcRef" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD></TD>
            </tr>
        </>
    )
}

export default TableFilters
