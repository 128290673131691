import React, { useCallback, useRef, useState } from 'react'
import Axios from '../../../axios-proas'
import { TD, FilterContainer, SelectFilter, Option, InputFilter } from '../../../styles/Table.styled'
import DateFilter from '../../UI/DateFilter/DateFilter';
import { useTranslation } from 'react-i18next';
import {showColumn} from '../../../shared/utility'
import AmountFilter from '../../UI/AmountFilter/AmountFilter';

function TableFilters(props) {
    const { filters, filtersChange, applyFilters, columns, userProfile, isCodaRefused} = props;
    const {t} = useTranslation();
    
    
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const [rangeShow, setRangeShow] = useState(false);
    const [rangeBTShow, setRangeBTShow] = useState(false);
    const [amountBT, setAmountBT] = useState(null)
    const [amountAT, setAmountAT] = useState(null)
    
    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    let [dateInputValue,setDateInputValue] = useState({
        lastActionDate: filters?.lastActionDate ? convertDate(filters.lastActionDate) : '',
        archibaldDate: filters?.archibaldDate ? convertDate(filters.archibaldDate) : '',
        issuingDate: filters?.issuingDate ? convertDate(filters.issuingDate) : '',
        dueDate: filters?.dueDate ? convertDate(filters.dueDate) : ''
    });

    const getLastActionDate = () => {
        return filters?.lastActionDate ? convertDate(filters.lastActionDate) : [];
    }

    const getArchibaldDate = () => {
        return filters?.archibaldDate ? convertDate(filters.archibaldDate) : [];
    }

    const getIssuingDate = () => {
        return filters?.issuingDate ? convertDate(filters.issuingDate) : [];
    }

    const getDueDate = () => {
        return filters?.dueDate ? convertDate(filters.dueDate) : [];
    }
    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        if (e.target.value?.length >= 3 || e.target.tagName !== "INPUT")
            applyFilters(newFilters);
        else if (e.target.value?.length < 3 && filters[key]?.length >= 3) {
            newFilters[key] = "";
            applyFilters(newFilters);
        }
    }

    const rangeATModalHandler = () =>{
        setRangeShow(true);
        getAmountATValues();
    }
    const getAmountATValues = () => {
         Axios.get("invoice//amountATValues",{
           params: {visualisation : filters.visualisation}
       }).then(response => {
             setAmountAT({
               start: +response.data.minAmountAT,
                end: +response.data.maxAmountAT
          })
       })
    }

    const getAmountAT = (field) => {
        if(filters?.inclusiveTaxAmount){
            let amount = filters?.inclusiveTaxAmount.split(":");
            if (field === "start")
                return amount[0]
            else return amount[1]
        }else{
            return '';
        }   
    }
    
    const amountAtChanged = (startval, endval) => {
        setAmountAT({
            start: startval,
            end: endval
        })
        let newFilters = {
            ...filters,
            inclusiveTaxAmount: startval + ':' + endval
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountATReset = () => {
        let newFilters = {
            ...filters,
            inclusiveTaxAmount: null
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const rangeBTModalHandler = () =>{
        setRangeBTShow(true);
        getAmountBTValues();
    }
    const getAmountBTValues = () => {
        Axios.get("invoice//amountBTValues",{
            params: {visualisation : filters.visualisation}
        }).then(response => {
            setAmountBT({
                start: +response.data.minAmountBT,
                end: +response.data.maxAmountBT
            })
        })
    }

    const getAmountBT = (field) => {
        if(filters?.exclusiveTaxAmount){
            let amount = filters?.exclusiveTaxAmount.split(":");
            if (field === "start")
                return amount[0]
            else return amount[1]
        }else{
            return '';
        }   
    }

    const amountBtChanged = (startval, endval) => {
        setAmountBT({
            start: startval,
            end: endval
        })
        let newFilters = {
            ...filters,
            exclusiveTaxAmount: startval + ':' + endval
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountBTReset = () => {
        let newFilters = {
            ...filters,
            exclusiveTaxAmount: null
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const closeAmountModal = () => {
        setRangeShow(false);
    }

    const closeAmountBTModal = () => {
        setRangeBTShow(false);
    }

    return (
        <>
            {
            <tr style={{position: "sticky",
                        top: "38px",
                        backgroundColor: "white",
                        zIndex: "99"}}>
                <TD></TD>
                <TD style={{display: !showColumn(columns,"processingStatus") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Statut traitement"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.processingStatus || ''} 
                            name="processingStatus" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                {!isCodaRefused && <><TD style={{display: !showColumn(columns,"rejectType") && 'none'}} >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.rejectType || ''} 
                            name="rejectType">
                            <Option value="">Type d'échec</Option>
                            <Option value="err01">err01 - Document hors scope</Option>
                            <Option value="err02">err02 - Fournisseur inconnu</Option>
                            <Option value="err03">err03 - Raison sociale incorrecte</Option>
                            <Option value="err04">err04 - Numéro de commande manquant</Option>
                            <Option value="err04a">err04a - N° commande incorrect</Option>
                            <Option value="err04b">err04b - Raison sociale non conforme au PO</Option>
                            <Option value="err05">err05 - Mentions légales obligatoires manquantes</Option>
                            <Option value="err06">err06 - Facture illisible ou incomplète</Option>
                            <Option value="err07">err07 - Facture non équilibrée</Option>
                            <Option value="err08">err08 - Étiquette TRS absente</Option>
                            <Option value="err09">err09 - Entité hors scope</Option>
                            <Option value="err10">err10 - Factures multiples dans un PDF</Option>
                            <Option value="err11">err11 - Erreur autre (exemple : erreurs multiples)</Option>
                            <Option value="err12">err12 - Problème de TVA intracommunautaire</Option>
                            <Option value="err51">err51 - Doublon</Option>
                            <Option value="err52">err52 - Faux doublon</Option>
                            <Option value="err99">err99 - Corbeille</Option>
                      </SelectFilter>
                    </FilterContainer>  
                </TD>

                <TD style={{display: !showColumn(columns,"originalRejectType") && 'none'}} >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.originalRejectType || ''} 
                            name="originalRejectType">
                            <Option value="">Type d’échec d'origine</Option>
                            <Option value="err01">err01 - Document hors scope</Option>
                            <Option value="err02">err02 - Fournisseur inconnu</Option>
                            <Option value="err03">err03 - Raison sociale incorrecte</Option>
                            <Option value="err04">err04 - Numéro de commande manquant</Option>
                            <Option value="err04a">err04a - N° commande incorrect</Option>
                            <Option value="err04b">err04b - Raison sociale non conforme au PO</Option>
                            <Option value="err05">err05 - Mentions légales obligatoires manquantes</Option>
                            <Option value="err06">err06 - Facture illisible ou incomplète</Option>
                            <Option value="err07">err07 - Facture non équilibrée</Option>
                            <Option value="err08">err08 - Etiquette TRS absente</Option>
                            <Option value="err09">err09 - Entité hors scope</Option>
                            <Option value="err10">err10 - Factures multiples dans un PDF</Option>
                            <Option value="err11">err11 - Erreur autre (exemple : erreurs multiples)</Option>
                            <Option value="err12">err12 - Problème de TVA intracommunautaire</Option>
                            <Option value="err51">err51 - Doublon</Option>
                            <Option value="err52">err52 - Faux doublon</Option>
                            <Option value="err99">err99 - Corbeille</Option>
                      </SelectFilter>
                    </FilterContainer>  
                </TD>
                <TD style={{display: !showColumn(columns,"manager") && 'none'}} >
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Responsable"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.manager || ''} 
                            name="manager" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>  
                </TD></>}
                <TD style={{display: !showColumn(columns,"archibaldDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle="Date d'envoi AGSRM"
                            from={getArchibaldDate()[0] || ''}
                            to={getArchibaldDate()[1] || ''}
                            name="archibaldDate"
                            activeReset={filters?.archibaldDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.archibaldDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"lastActionDate")&& 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle="Date de dernière action"
                            from={getLastActionDate()[0] || ''}
                            to={getLastActionDate()[1] || ''}
                            name="lastActionDate"
                            activeReset={filters?.lastActionDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.lastActionDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"lastActionAuthor") && 'none'}}>
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.lastActionAuthor || ''} 
                            name="lastActionAuthor">
                            <Option value="">Action/évènement</Option>
                            <Option value="ACCOUNTING">Comptable four</Option>
                            <Option value="ACCOUNTING_TLV">Comptable four TLV</Option>
                            <Option value="SUPPLIER">Fournisseur</Option>
                            <Option value="REJECT_MANAGER">Gestionnaire conformité</Option>
                            <Option value="BUYER">Gestionnaire Achats</Option>
                            {/* <Option value="RVCA">RVCA</Option> */}
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"channel") && 'none'}}>
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.channel || ''} 
                            name="channel">
                            <Option value="">Type de dépôt</Option>
                            <Option value="PAPER">Papier</Option>
                            <Option value="EMAIL">Email</Option>
                            <Option value="PORTAL">Portail</Option>
                            <Option value="EDI">EDI</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"suCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Code SU"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.suCode || ''} 
                            name="suCode" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"ownerName") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Nom entité"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.ownerName || ''} 
                            name="ownerName" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"supplierCode")&& 'none'}} >
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Code tiers"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.supplierCode || ''} 
                            name="supplierCode" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"supplierName")&& 'none'}} >
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Nom fournisseur"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.supplierName || ''} 
                            name="supplierName" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"number") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="N° de facture"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.number || ''} 
                            name="number" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"documentType") && 'none'}}>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.documentType || ''} 
                        name="documentType" 
                        id="exampleFormControlSelect2"
                    >
                        <Option value="">Type</Option>
                        <Option value="INV">{t("invoiceList:invoice")}</Option>
                        <Option value="CRN">{t("invoiceList:creditNote")}</Option>
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"inclusiveTaxAmount") && 'none'}}>
                    <FilterContainer>
                        <AmountFilter
                            modalTitle={t('tableHead:inclusiveTaxAmount',"Montant TTC" ) } 
                            from={getAmountAT("start") || ''}
                            to={getAmountAT("end") || ''}
                            total={0}
                            name="inclusiveTaxAmount"
                            activeReset={filters?.inclusiveTaxAmount ? true : false} 
                            resetInterval={amountATReset}
                            defaultVal={amountAT} 
                            defaultValStart={+getAmountAT("start") || amountAT?.start} 
                            defaultValEnd={+getAmountAT("end") || amountAT?.end}   
                            value={getAmountAT}
                            show={rangeShow}  
                            showModal={rangeATModalHandler} 
                            closeModal={closeAmountModal} 
                            amountChanged={amountAtChanged}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"exclusiveTaxAmount")&& 'none'}}>
                    <FilterContainer>
                        <AmountFilter
                            modalTitle={t('tableHead:exclusiveTaxAmount',"Montant HT" ) } 
                            from={getAmountBT("start") || ''}
                            to={getAmountBT("end") || ''}
                            total={0}
                            name="exclusiveTaxAmount"
                            activeReset={filters?.exclusiveTaxAmount ? true : false} 
                            resetInterval={amountBTReset}
                            defaultVal={amountBT}
                            defaultValStart={+getAmountBT("start") || amountBT?.start} 
                            defaultValEnd={+getAmountBT("end") || amountBT?.end}  
                            value={getAmountBT}
                            show={rangeBTShow}  
                            showModal={rangeBTModalHandler} 
                            closeModal={closeAmountBTModal} 
                            amountChanged={amountBtChanged}
                        />
                    </FilterContainer>  
                </TD>
                <TD style={{display: !showColumn(columns,"orderNumber")&& 'none'}} >
                    <FilterContainer>
                        <InputFilter 
                            placeholder="N° de cde"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.orderNumber || ''} 
                            name="orderNumber" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"issuingDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle="Date facture"
                            from={getIssuingDate()[0] || ''}
                            to={getIssuingDate()[1] || ''}
                            name="issuingDate"
                            activeReset={filters?.issuingDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.issuingDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                {isCodaRefused && <TD style={{display: !showColumn(columns,"refusalDescription") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Description de l'erreur"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.refusalDescription || ''} 
                            name="refusalDescription" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>}
                <TD style={{display: !showColumn(columns,"compostingNumber") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="N° de compostage"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.compostingNumber || ''} 
                            name="compostingNumber" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"unreadMessages") && 'none'}}>
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.unreadMessages || ''} 
                            name="unreadMessages" 
                            id=""
                        >
                            <Option value="">Commentaires</Option>
                            <Option value="0">Lus</Option>
                            <Option value="1">Non-lus</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"dueDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle="Date d'échéance"
                            from={getDueDate()[0] || ''}
                            to={getDueDate()[1] || ''}
                            name="dueDate"
                            activeReset={filters?.dueDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.dueDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"ivmStatus") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Statut intégration IVM"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.ivmStatus || ''} 
                            name="ivmStatus" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"chronoCode") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Chrono-endossage"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.chronoCode || ''} 
                            name="chronoCode" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
            </tr>}
        </>
    )
}

export default TableFilters
