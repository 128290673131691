import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../axios-proas'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {CardContainer, HomeContainer, HomeHeader, HeaderActions, HeaderAction, HomeBody, HomeCards, HomeStats, Coselect,TableGRContainer,HomeInvoices} from './Home.styled'
import Card from '../../components/Home/Card/Card';
import PeopleIcon from '@mui/icons-material/People';
import CardSupplier from '../../components/Home/Card/CardSupplier';
import LastEvents from './LastEvents/LastEvents';
import Import from './Import/Import';
import { getNotyfObject } from '../../shared/utility';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { SupplierLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EuroIcon from '@mui/icons-material/Euro';
import {formatDateEur} from '../../shared/utility'
import CheckIcon from '@mui/icons-material/Check';
import { connect } from 'react-redux';
import "./Home.css"
import Rejets  from './RejectTracking/Rejets';
import InfoBand from '../../components/InfoBand/InfoBand';
import TopAndFlop from "./Top10/TopAndFlop"
import ReferentielFournisseur from './ReferentielFournisseur/ReferentielFournisseur';
import ReplayIcon from '@mui/icons-material/Replay';
import { TableAction, TAContainer } from '../../styles/Table.styled';
import * as actions from '../../store/actions/index';
import CardErr00 from '../../components/Home/Card/CardErr00';
import InvoicesPerChannelChart from '../../components/Home/InvoicesPerChannelChart/InvoicesPerChannelChart';
import RejectsResolutionKPI from '../../components/Home/RejectsResolutionKPI/RejectsResolutionKPI';
import RejectsPerSupplierKPI from '../../components/Home/RejectsPerSupplierKPI/RejectsPerSupplierKPI';
import DatedRejects from './DatedRejects/DatedRejects';
import RejectsPerManager from './RejectsPerManager/RejectsPerManager';
import PendingAndTreatedRejectsPerManager from './PendingAndTreatedRejectsPerManager/PendingAndTreatedRejectsPerManager';
import AdminInfoBands from '../../components/Home/AdminInfoBands/AdminInfoBands';
import InvoicesPerMonth from '../../components/Home/InvoicesPerMonth/InvoicesPerMonth';
import ImportAdmin from './Import/ImportAdmin';
function Home({userLevel, userProfile, statuses, getInvoiceCounters,bandeaux,updateBandeau,newprofile}) {
    const notyf = getNotyfObject()
    const [ownerGlobalFilter, setOwnerGlobalFilter] = useState();
    const [notPaidInvoices , setNotPaidInvoices] = useState(0);
    const [litigationInvoices, setLitigationInvoices] = useState(0);
    const[litigationInvoicesCurrency,setLitigationInvoicesCurrency] = useState(0);
    const BUYER = ["BUYER"];
    const ACCOUNTING = ["ACCOUNTING","ACCOUNTING_TLV"];
    const ADMIN = ["ADMIN"];
    const [invoicesRefused,setInvoicesRefused] = useState();
    const defaultTime = new Date();
    defaultTime.setFullYear(defaultTime.getFullYear()-1);
    const [startDate , setStartDate] = useState(formatDateEur(defaultTime.getTime()));
    const [endDate , setEndDate] = useState(formatDateEur(new Date().getTime()));
    const [waitingForInvoiceTreated, setWaitingForInvoiceTreated] = useState(0);
    const [suppliersForFilter, setSuppliersForFilter] = useState([]);
    const[supplierGlobalFilter, setSupplierGlobalFilter] = useState([]);
    const [groupsForFilter, setGroupsForFilter] = useState([]);
    const [groupsGlobalFilter, setGroupsGlobalFilter] = useState([]);
    const navigate = useNavigate();
   const [bandeauxState, setBandeauxState] = useState([]);

    //ajouter par marzouk saibi
    const columnsSupplierConnectedTopAndFlop = [
        {field: 'name', title: 'Nom',width: '130px'},
        {field: 'code', title: 'Code',width: '75px'},
        {field: 'invoicesQuantity', title: 'Nombre factures',width: '50px'}   
    ];
    const columnsSupplierEchangeTopAndFlop = [
        {field: 'name', title: 'Nom',width: '130px'},
        {field: 'code', title: 'Code',width: '75px'},
        {field: "interactionsQuantity", title: "Nombre d'échanges",width: '50px'}   
    ];
    const columnsUsersConnectedTopAndFlop = [
        {field: 'firstName', title: 'Prénom',width: '40%'},
        {field: 'lastName', title: 'Nom de famille',width: '40%'},
        {field: "signinQuantity", title: "Nb connexions",width: '20%'}   
    ];

    const [dataSupplierConnectedTop, setDataSupplierConnectedTop] = useState([]);
    const [dataSupplierConnectedFlop, setDataSupplierConnectedFlop] = useState([]);
    const [dataSupplierEchangeTop, setDataSupplierEchangeTop] = useState([]);
    const [dataSupplierEchangeFlop, setDataSupplierEchangeFlop] = useState([]);
    const [dataUserConnectedTop, setDataUserConnectedTop] = useState([]);
    const [dataUserConnectedFlop, setDataUserConnectedFlop] = useState([]);
    const [utilisateurParProfileData,setUtilisateurParProfileData] = useState([]);
    const [utilisateurParProfileType,setUtilisateurParProfileType] = useState([]);
    const [noConnectedSuppliers,setNoConnectedSuppliers] = useState();
    const [enlistedSuppliers,setEnlistedSuppliers] = useState();

    const [turnover,setTurnover] = useState();
    const [turnoverCurrency,setTurnoverCurrency] = useState();
    const [pendingInvoicesForTheCurrentMonthCurrency,setPendingInvoicesForTheCurrentMonthCurrency] = useState();
    const [payedInvoicesForTheCurrentMonthCurrency,setPayedInvoicesForTheCurrentMonthCurrency] = useState();
    const [pendingInvoicesForTheCurrentMonth,setPendingInvoicesForTheCurrentMonth] = useState();
    const [payedInvoicesForTheCurrentMonth,setPayedInvoicesForTheCurrentMonth] = useState();
    const [suppliersOver6RollingMonths,setSuppliersOver6RollingMonths] = useState();
    const [ rejectedInvoicesCurrency,setRejectedInvoicesCurrency] = useState();
    const [ rejectedInvoices,setRejectedInvoices] = useState();
    const [profileForGetUser, setProfileForGetUser] = useState("ADMIN");
    const [entitiesOwner,setEntitiesOwner] = useState();

    const [fnrInvoices,setFnrInvoices] = useState();
    const [invoicesEcheanceDepassee,setInvoicesEcheanceDepassee] = useState();
    /////////////////////////////////////////////////////////////////BUYER///////////////////////////////////////////////////////////////////////////////////////////////

    const getRolling6monthTurnover=(data)=>{    
        Axios.get('/entity//rolling6monthTurnover').then((res) => {      
            setTurnover(res.data?.som); 
            setTurnoverCurrency(res.data?.currencies);         
        }).catch(err => {
            notyf.error(err.message);
        })
    }

    const getInvoicesPendingPaymentAndPayedForTheCurrentMonth=(data)=>{ 
        let u =supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(',');     
        const groups = groupsGlobalFilter?.map(item => "'"+item.value+"'").join(',');     
        Axios.get('/entity//invoicesPendingPaymentAndPayedForTheCurrentMonth',{params:{entity:ownerGlobalFilter,suppliers:u, groups: groups}}).then((res) => {      
            setPendingInvoicesForTheCurrentMonth(res.data?.pending); 
            setPayedInvoicesForTheCurrentMonth(res.data?.payed); 
            setPendingInvoicesForTheCurrentMonthCurrency(res.data?.pendingCurrencies);
            setPayedInvoicesForTheCurrentMonthCurrency(res.data?.payedCurrencies)
        }).catch(err => {
            notyf.error(err.message);
    })
    }
    const getNumberOfSuppliersOver6RollingMonths=(data)=>{    
        Axios.get('/entity//numberOfSuppliersOver6RollingMonths').then((res) => {      
            setSuppliersOver6RollingMonths(res.data?.count);          
        }).catch(err => {
            notyf.error(err.message);
    })
    }

    const getInvoicesRejected=(data)=>{   
        let u =supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(',');
        const groups = groupsGlobalFilter?.map(item => "'"+item.value+"'").join(',');     
        Axios.get('/entity//invoicesRejected',{params:{entity:ownerGlobalFilter,suppliers:u, groups: groups}}
        ).then((res) => {      
            setRejectedInvoicesCurrency(res.data?.rejectedCurrencies);  
            setRejectedInvoices(res.data?.rejected);        
        }).catch(err => {
            notyf.error(err.message);
    })
    }

    const getLitigationInvoices = () => {
        let u =supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(',');   
        const groups = groupsGlobalFilter?.map(item => "'"+item.value+"'").join(',');     
        Axios.get("/invoice//litigationInvoices",{
            params: {
                endDate:endDate ,
                startDate:startDate,
                entity:ownerGlobalFilter,
                suppliers:u,
                groups: groups
            }
        }).then(res => {
            if(res?.data){
            setLitigationInvoices(res.data.count)
            setLitigationInvoicesCurrency(res.data.currencies)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

        useEffect(() => {
            getInvoiceCounters(ownerGlobalFilter, supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(','), groupsGlobalFilter?.map(item => "'"+item.value+"'").join(','));
            if(BUYER.includes(userProfile)){
                getLitigationInvoices();
                getInvoicesRejected();
                getRolling6monthTurnover();
                getInvoicesPendingPaymentAndPayedForTheCurrentMonth();
                getNumberOfSuppliersOver6RollingMonths();
            } else if(ACCOUNTING.includes(userProfile)){
                getInvoicesRefused();
                getLitigationInvoices();
                getInvoicesFNR();
                getInvoicesRejected();
            } else if (userProfile === "REJECT_MANAGER") {
                if (userLevel === "standard")
                    Axios.get("reject//waitingForCreditNoteTreatedCount").then(response => {
                        setWaitingForInvoiceTreated(response.data?.count);
                    })
            }
        }, [supplierGlobalFilter, ownerGlobalFilter, groupsGlobalFilter, userProfile, userLevel]);

    ///////////////////////////////////////////////////////////////END BUYER///////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////ACCOUNTANT//////////////////////////////////////////////////////////////////////////////////////////////
    const getInvoicesFNR=(data)=>{ 
        let u =supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(',');    
        const groups = groupsGlobalFilter?.map(item => "'"+item.value+"'").join(',');     
        Axios.get('/entity//invoicesFNR',{params:{entity:ownerGlobalFilter,suppliers:u, groups: groups}}).then((res) => {      
            setFnrInvoices(res.data?.FnrInvoices);
        setInvoicesEcheanceDepassee(res.data?.FnrInvoicesEcheanceDepassee);     
        }).catch(err => {
            notyf.error(err.message);
    })
    }


    const getInvoicesRefused=(data)=>{ 
        let u =supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(',');    
        const groups = groupsGlobalFilter?.map(item => "'"+item.value+"'").join(',');     
        Axios.get('/entity//invoicesRefused',{params:{entity:ownerGlobalFilter,suppliers:u, groups: groups}}).then((res) => {      
        
        setInvoicesRefused(res.data?.InvoicesRefused);     
        }).catch(err => {
            notyf.error(err.message);
    })
    }

///////////////////////////////////////////////////////////////END ACCOUNTANT//////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////ADMIN////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if(userProfile=="ADMIN"){
            // getUtilisateurParProfile();
            getConnectedAndNoConnectedAndEnlistedSuppliers();
            // getTopAndFlopSupplier();
            // getTopAndFlopEchangeSuppliers();
        } else if (userLevel === "admin") {
           // setProfileForGetUser(userProfile);
            Axios.get('/entity/'+userProfile+'/topAndFlopconnectedUsersProfile').then((response) => {      
                setDataUserConnectedTop(response.data?.top);
                setDataUserConnectedFlop(response?.data?.flop)    
            }).catch(err => {
                notyf.error("Une erreur s'est produite!");
            })
        }
       return ()=>{
       //setProfileForGetUser("ADMIN");
       }
    }, [userProfile, userLevel])

    /*const getUtilisateurParProfile=()=>{
        Axios.get('/invoice//utilisateurParProfile').then((res) => {      
            setUtilisateurParProfileData(res?.data?.count)
            setUtilisateurParProfileType(res?.data?.currencies)    
    }).catch(err => {
        notyf.error(err.message);
    })
    }*/
    const getConnectedAndNoConnectedAndEnlistedSuppliers=()=>{
        Axios.get('/entity//connectedAndNoConnectedAndEnlistedSuppliers').then((res) => {      
            setNoConnectedSuppliers(res.data?.connected?.total); 
            // setEnlistedSuppliers(res.data?.enroles?.total);           
        }).catch(err => {
            notyf.error(err.message);
        })
    }

    // const getTopAndFlopSupplier=()=>{
    //     Axios.get('/entity//topAndFlopSupplier').then((response) => {      
    //         setDataSupplierConnectedTop(response.data?.top);
    //         setDataSupplierConnectedFlop(response?.data?.flop)    
    //     }).catch(err => {
    //         notyf.error("Une erreur s'est produite!");
    //     })
    // }
    // const getTopAndFlopEchangeSuppliers=()=>{
    //     Axios.get('/entity//topAndFlopEchangeSuppliers').then((response) => {      
    //         setDataSupplierEchangeTop(response.data?.top);
    //         setDataSupplierEchangeFlop(response?.data?.flop)    
    //     }).catch(err => {
    //         notyf.error("Une erreur s'est produite!");
    //     })
    // }

    // useEffect(() => { 
    //     if(ADMIN.includes(userProfile)){
    //         Axios.get('/entity/'+profileForGetUser+'/topAndFlopconnectedUsersProfile').then((response) => {      
    //             setDataUserConnectedTop(response.data?.top);
    //             setDataUserConnectedFlop(response?.data?.flop)
    //         }).catch(err => {
    //             notyf.error("Une erreur s'est produite!");
    //         })
    //     }
    // },[profileForGetUser,userProfile])
    ///////////////////////////////////////////////////////////////////////////ADMIN END///////////////////////////////////////////////////////////////



    useEffect(() => {
        Axios.get('/entity//distinctOwners?orderBy=code').then(response => {
        
            let li=[];
            response?.data?.map((item,i)=>{  
                let p={
                    value: item.uid,
                    label: item.code + " " + item.name,
                    name: item.name,
                    code: item.code,
                };
                li = li.concat(p);            
            });  


            setEntitiesOwner([{label: '-- Choisir une entité --', value: ''}, ...li]);
        })

        loadSuppliersForFilter(null);
        loadGroupsForFilter(null);
    }, [])
    const [entityAW,setEntityAW]=useState(null);
    const entityAWChangeHandler = (e) => {
        setEntityAW(e);
        setOwnerGlobalFilter(e.value)
    }

    const getIdStatus=(code)=>{
        return (statuses?.find(s => s.aifeCode == code)?.id);
    }

    
    const changeSupplierFilterHandler = (e) => {
        if(e.length > 0 && e[0].value !=='' ){
            setSupplierGlobalFilter(e);
            loadSuppliersForFilter(null,e);
        }else{
            setSupplierGlobalFilter(null)
            loadSuppliersForFilter(null,null);
        }
    };

    const loadSuppliersForFilter = (input,supplierGlobalFilter) => {
        Axios.get("/entity//suppliersForFilter?searchFilters="+(input ? input : "") + (supplierGlobalFilter?.length > 0 ? "&startCode="+supplierGlobalFilter[0].startCode : "")).then(response => {
            setSuppliersForFilter(response?.data?.map(s => ({value: s.uid, label: s.code + " " + s.name, startCode: s.startCode,name:s.name,code:s.code})));
        }).catch(err => {
            setSuppliersForFilter([]);
        })
    }

    const changeGroupFilterHandler = (e) => {
        if(e.length > 0 && e[0].value !=='' ){
            setGroupsGlobalFilter(e);
            loadGroupsForFilter(null);
        }else{
            setGroupsGlobalFilter(null)
            loadGroupsForFilter(null);
        }
    };

    const loadGroupsForFilter = input => {
        Axios.get("/regroupement?pageSize=15&orderBy=title"+(input ? "&title=" + input : "")).then(response => {
            setGroupsForFilter(response?.data?.result?.map(g => ({value: g.uid, label: g.title, entities: g.entities})));
        }).catch(err => {
            setGroupsForFilter([]);
        })
    }

    let dateNew=new Date().getTime();

    const getAllBandeauxInProgress = () => {
        Axios.get('/user/'+dateNew+'/AllBandeauxInProgress', {params: {
            profile:userProfile
        }})
        .then((response) => {  
        
            const DataTemp = response?.data?.bandeaux.map(row => {
                return {
                    ...row,
                    read: bandeaux?.find(b => b.uid === row.uid)?.read? bandeaux?.find(b => b.uid === row.uid)?.read : false,
                   

                } 
            })
            setBandeauxState(DataTemp);
          
         
            updateBandeau(DataTemp);
      
            
        
          
        }).catch(res => {
            notyf.error("Une erreur s'est produite !");
        })
    };

    useEffect(() => {
       
        getAllBandeauxInProgress(); 
    }, [userProfile])
const restHandler = () => {
    setOwnerGlobalFilter('');
    setSupplierGlobalFilter([]);
    loadSuppliersForFilter(null,null);
    setGroupsGlobalFilter([]);
    loadGroupsForFilter(null);

}
  return (
      <>
        <HomeContainer>
            {bandeaux.length > 0 && (userProfile === "ADMIN" ? <AdminInfoBands infoBands={bandeaux} /> : <InfoBand setBandeaux={updateBandeau} bandeaux={bandeaux} userProfile={userProfile}/>)}
            
            {
            !["REJECT_MANAGER", "ADMIN"].includes(userProfile) && 
            <HomeHeader>
        
                <HeaderActions>
                    <TableAction height={"42px"} title="Réinitialiser les filtres" onClick={() => restHandler()}>
                        <ReplayIcon />
                    </TableAction>
                    <HeaderAction style={{ width: "20%"}}>
                       <Coselect
                            name="mailType"
                            onChange={(e)=>changeGroupFilterHandler(e)} 
                            onInputChange={(input, actionMeta) => {if (["input-change", "menu-close"].includes(actionMeta.action)) loadGroupsForFilter(input)}}
                            placeholder={"-- Choisir un groupe --"}
                            noOptionsMessage={() => "Aucun résultat"}
                            value={groupsGlobalFilter}
                            isClearable={true}
                            isRtl={false}
                            isMulti
                            options={groupsForFilter}
                        />
                    </HeaderAction>
                    <HeaderAction style={{ width: "24%"}}>
                       <Coselect
                            name="mailType"
                            onChange={(e)=>changeSupplierFilterHandler(e)} 
                            onInputChange={(input, actionMeta) => {if (["input-change", "menu-close"].includes(actionMeta.action)) loadSuppliersForFilter(input,supplierGlobalFilter)}}
                            placeholder={"-- Choisir un fournisseur --"}
                            noOptionsMessage={() => "Aucun résultat"}
                            value={supplierGlobalFilter}
                            isClearable={true}
                            isRtl={false}
                            isMulti
                            options={suppliersForFilter}
                        />   
                    </HeaderAction>
                    <HeaderAction>
                        <Coselect 
                            placeholder={"-- Choisir une entité --"}
                            options={entitiesOwner} 
                            value={entitiesOwner?.find(option => option.value == ownerGlobalFilter)} 
                            onChange={(e)=>entityAWChangeHandler(e)} 
                        />
                        <div style={{marginRight:"5px"}}></div>
                    </HeaderAction>
                </HeaderActions>
            </HomeHeader>}
            <HomeBody>
               
                <div style={{display:"flex",width:"100%",    justifyContent: "space-between"}}>
                <HomeStats>
                    {
                        BUYER.includes(userProfile) &&
                            <HomeCards>
                              <HomeCards style={{width: userLevel === "admin" ? "65%" : "100%"}}>
                                <CardSupplier   
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    icon={<LocalShippingIcon style={{color: "#f00"}} />} 
                                    title="Nombre de fournisseurs ayant facturé sur 6 mois glissants" 
                                    currencies={[]}
                                    message={"Fournisseur"}
                                    value={suppliersOver6RollingMonths}
                                />
                                <Card
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    icon={<EuroIcon style={{ color: "#f00" }} />}
                                    title={"Chiffre d’affaires sur 6 mois glissants"}
                                    value={turnover}
                                    currencies={turnoverCurrency}
                                    list={true}
                                    />
                                <Card   
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    icon={<ReportProblemIcon style={{color: "#F9B959"}} />}  
                                    title="Factures en litige"
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeFilter: getIdStatus("207"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                    value={litigationInvoices}
                                    currencies={litigationInvoicesCurrency}
                                    list={true}
                                />
                                <Card    
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeFilter: getIdStatus("213"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                    title="Factures en rejet" 
                                    value={rejectedInvoices}
                                    currencies={rejectedInvoicesCurrency}
                                    list={true}
                                />
                                <Card  
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    icon={<HourglassEmptyIcon style={{color: "#f00"}} />} 
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeFilter: getIdStatus("205"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                    title="Factures en attente de paiement du mois en cours" 
                                    value={pendingInvoicesForTheCurrentMonth}
                                    currencies={pendingInvoicesForTheCurrentMonthCurrency}
                                    list={true}
                                />
                                <Card  
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeFilter: getIdStatus("211"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                    title="Factures payées du mois en cours" 
                                    value={payedInvoicesForTheCurrentMonth}
                                    value4={payedInvoicesForTheCurrentMonth} 
                                    currencies={payedInvoicesForTheCurrentMonthCurrency}
                                    list={true}
                                    />
                                    </HomeCards>
                                {userLevel === "admin" && <TopAndFlop 
                                    width={"34%"}
                                    columns={columnsUsersConnectedTopAndFlop} 
                                    dataTop={dataUserConnectedTop} 
                                    dataFlop={dataUserConnectedFlop} 
                                    title={"TOP 10 utilisateurs gestionnaires Achats les plus/moins connectés"}
                                    haveList={true}
                                    showList={false}
                                />}
                    
                            </HomeCards>
                            
                    }
                    {
                        ACCOUNTING.includes(userProfile) ?
                        <HomeCards>
                            <HomeCards style={{width: userLevel === "admin" ? "65%" : "100%"}}>
                                <Card 
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeListFilter: [getIdStatus("204"), getIdStatus("207")], entityAW:entityAW, supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }                                  
                                    title="FNR" 
                                    value={fnrInvoices}
                                    currencies={fnrInvoices}
                                    list={true}
                                />
                                <Card 
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    title="Factures en litige"
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeFilter: getIdStatus("207"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                    value={litigationInvoices}
                                    currencies={litigationInvoicesCurrency}
                                    list={true}
                                />
                                {userLevel === "admin" ? <>
                                    <Card 
                                        width={userLevel === "admin" ? "47%" : "33%"}
                                        navigateTo={"/fournisseurInvoices"}
                                        clickFilters={{statusCodeListFilter: [getIdStatus("204"), getIdStatus("207")],entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter, dueDate:("1:"+Date.now())} }
                                        title="FNR échéance dépassée" 
                                        value={invoicesEcheanceDepassee}
                                        currencies={invoicesEcheanceDepassee}
                                        list={true}
                                    />
                                    <Card 
                                        width={userLevel === "admin" ? "47%" : "33%"}
                                        navigateTo={"/fournisseurInvoices"}
                                        clickFilters={{statusCodeFilter: getIdStatus("213"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                        title="Factures rejetées"  
                                        value={rejectedInvoices}
                                        currencies={rejectedInvoicesCurrency}
                                        list={true}
                                    />
                                    <div style={{width : "47%"}}></div>
                                </> : <>
                                    <Card 
                                        width={userLevel === "admin" ? "47%" : "33%"}
                                        navigateTo={"/fournisseurInvoices"}
                                        clickFilters={{statusCodeFilter: getIdStatus("213"),entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter} }
                                        title="Factures rejetées"  
                                        value={rejectedInvoices}
                                        currencies={rejectedInvoicesCurrency}
                                        list={true}
                                    />
                                    <Card 
                                        width={userLevel === "admin" ? "47%" : "33%"}
                                        navigateTo={"/fournisseurInvoices"}
                                        clickFilters={{statusCodeListFilter: [getIdStatus("204"), getIdStatus("207")],entityAW:entityAW,supplier:supplierGlobalFilter, groups: groupsGlobalFilter, dueDate:("1:"+Date.now())} }
                                        title="FNR échéance dépassée" 
                                        value={invoicesEcheanceDepassee}
                                        currencies={invoicesEcheanceDepassee}
                                        list={true}
                                    />
                                </>}
                                
                                <Card 
                                    width={userLevel === "admin" ? "47%" : "33%"}
                                    navigateTo={"/fournisseurInvoices"}
                                    clickFilters={{statusCodeFilter: getIdStatus("210"), entityAW:entityAW, supplier:supplierGlobalFilter, groups: groupsGlobalFilter}}
                                    title="Factures refusées" 
                                    value={invoicesRefused}
                                    currencies={invoicesRefused}
                                    list={true}
                                />
                            </HomeCards>
                            {userLevel === "admin" && <TopAndFlop
                                width={"34%"}
                                columns={columnsUsersConnectedTopAndFlop} 
                                dataTop={dataUserConnectedTop} 
                                dataFlop={dataUserConnectedFlop} 
                                title={`TOP 10 utilisateurs comptables les plus/moins connectés`}
                                haveList={true}
                                showList={false}
                            />}
                        </HomeCards>
                        : null
                    }
                  {ADMIN.includes(userProfile) ?
                    <HomeCards>
                      
                        {/* <TopAndFlop 
                                columns={columnsSupplierConnectedTopAndFlop}
                                dataTop={dataSupplierConnectedTop}
                                title={"TOP 10 fournisseurs les plus/moins connectés"} 
                                dataFlop={dataSupplierConnectedFlop}
                                emptyListMessage={"Aucun fournisseur connecté sur la période"}/>                                
                        <TopAndFlop 
                                columns={columnsSupplierEchangeTopAndFlop} 
                                dataTop={dataSupplierEchangeTop} 
                                title={"TOP 10 fournisseurs avec le plus/moins d’échanges"}
                                dataFlop={dataSupplierEchangeFlop}
                                emptyListMessage={"Aucun fournisseur connecté sur la période"}/>
                        <TopAndFlop 
                                profileForGetUser={profileForGetUser}
                                setProfileForGetUser ={setProfileForGetUser}
                                columns={columnsUsersConnectedTopAndFlop} 
                                dataTop={dataUserConnectedTop} 
                                dataFlop={dataUserConnectedFlop} 
                                title={"TOP 10 utilisateurs les plus/moins connectés"}
                                haveList={true}
                                showList={true}
                                emptyListMessage={"Aucun utilisateur connecté sur la période"}/>  */}
                        <CardContainer style={{width: "40%", height: "fit-content"}}>         
                            {/* <CardSupplier width="47%"
                                    icon={<LocalShippingIcon style={{color: "#f00"}} />}
                                    title={"Nombre de fournisseurs enrôlés "}
                                    value={enlistedSuppliers }
                                    currencies={[]}
                                    message={"fournisseur"}
                                /> */}
                        
                            <CardSupplier width="47%"
                                icon={<ExitToAppIcon style={{color: "#f00"}} />}
                                    title={"Nombre de fournisseurs inscrits"}
                                    value={noConnectedSuppliers}
                                    currencies={[]}
                                    message={"fournisseur"}
                                    />
                            {/* <CardSupplier width="47%"
                                    icon={<PeopleIcon style={{color: "#f00"}} />} 
                                    title={"Utilisateurs par profil "}
                                    value={utilisateurParProfileData}
                                    currencies={utilisateurParProfileType}
                                    list={true}
                                    isUserProfile={true}
                                    message={"utilisateur"}
                                    clickHandler={() => {navigate("/usersManagement")}} 
                                    /> */}
                            <CardErr00 />
                            {/* <InvoicesPerChannelChart /> */}
                            <InvoicesPerMonth />
                        </CardContainer>
                        <CardContainer style={{width: "34%", height: "fit-content"}}>
                            <RejectsPerSupplierKPI />
                            <RejectsResolutionKPI />
                        </CardContainer>
                        <CardContainer style={{width: "24%"}}>
                            <ImportAdmin />
                        </CardContainer>
                    </HomeCards>
                        
                    :null}
                    {
                        userProfile == "REJECT_MANAGER" && <>
                        <TableGRContainer>
                            <DatedRejects />
                            <Import width={"25%"} />
                            {userLevel == "standard" && <CardSupplier width="25%"
                                icon={<CheckIcon style={{color: "#f00"}} />}
                                title={"Rejets en attente d'avoir et clôturés"}
                                value={waitingForInvoiceTreated}
                                currencies={[]}
                                message={"facture"}
                                clickHandler={() => navigate("/rejectWaitingForCreditNoteTreated")}
                            />}
                            {/* userLevel === "admin" && <TopAndFlop
                                  width={"34%"}
                                  columns={columnsUsersConnectedTopAndFlop} 
                                  dataTop={dataUserConnectedTop} 
                                  dataFlop={dataUserConnectedFlop} 
                                  title={"TOP 10 utilisateurs gestionnaires Rejets les plus/moins connectés"}
                                  haveList={true}
                                  showList={false}
                              /> */}
                            {userLevel == "admin" && <ul style={{width:"38%", padding: "0"}}>
                                <RejectsPerManager />
                                <PendingAndTreatedRejectsPerManager/>
                            </ul>}
                        </TableGRContainer>
                        </>
                    }
                </HomeStats>
                    {/* <Events userProfile={userProfile}events={eventData} /> */}
                </div>
                {
                    !["ADMIN", "REJECT_MANAGER"].includes(userProfile) &&
                   
                        <HomeInvoices>
                            {/* <LastInvoices entityFilter={"supplier"}/> */}
                            <LastEvents  suppliers ={supplierGlobalFilter?.map((item) => "'"+item.value+"'").join(',')} entity={ownerGlobalFilter} groups={groupsGlobalFilter?.map((item) => "'"+item.value+"'").join(',')} statuses={statuses}   />
                            {/* <ReferentielFournisseur/> */}
                        </HomeInvoices>
                }
                {/*
                    ADMIN.includes(userProfile) &&
                   
                        <HomeInvoices>
                            {/* <LastInvoices entityFilter={"supplier"}/>}
                           
                            <ReferentielFournisseur/>
                        </HomeInvoices>
                */}
                


            </HomeBody>
        </HomeContainer>
      </>
  );
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    userLevel : state.auth.userLevel,
    userProfile : state.auth.profile,
    login : state.auth.login,
    bandeaux : state.users.bandeaux,
    newprofile: state.auth.newprofile
})

const mapDispatchToProps = dispatch => ({
    getInvoiceCounters: (entity, suppliers, groups) => dispatch(actions.invoiceGetCounters(entity, suppliers, groups)),
   updateBandeau: (a) => dispatch(actions.updateBandeaux(a))
})

export default connect(mapStateToProps, mapDispatchToProps) (Home)
