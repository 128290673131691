import React, { useMemo, useState, useEffect } from "react";
import Axios from "../../../axios-proas";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { convertFileToBase64, getNotyfObject } from "../../../shared/utility";
import Spinner from "react-bootstrap/Spinner";
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';
import MandatoryMarker from '../../../components/UI/MandatoryMarker';
import {
  AddRecipientsBtn,
  AttachementsContainer,
  CFBody,
  CFContainer,
  CFEditorWrapper,
  CFFormGroup,
  CFFormSection,
  CFFormWrapper,
  CFHeader,
  CFHeaderActions,
  CFHeaderTitle,
  CFHeaderWrapper,
  CFSection,
  CFTitle,
  DPContainer,
  ToggleIcon,
  ToggleLabel,
} from "./NewInfoband.styled";
import {
  BtnOutlineAction,
  BtnOutlineLink,
  FormInput,
  FormLabel,
} from "../../../styles/Common";
import { useTranslation } from "react-i18next";

import { useTheme } from "styled-components";

import TextareaAutosize from "@mui/material/TextareaAutosize";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  NDContainer,
  DocVisu,
  NoContent,
  NDCol6,
  NDrow,
  ISContainer,
  EIFormGroup,
  EIFormLabel,
  Input,
  EIFormGroupChecked,
  ToggleDiv,
  InputCheckbox,
  EntitiesContainer,
} from "../../NewDocumentation/NewDocumentation.styled";
import { padding } from "@mui/system";
import BandCouleurs from "./BandCouleurs";
function NewInfoband() {
  let { uid } = useParams();
  let notyf = getNotyfObject();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const currentDate = new Date();
  const [selectedStartDate, setSelectedStartDate] = React.useState(currentDate);
  const [campaignErrors, setCampaignErrors] = useState([]);
  const [bandeauData, setBandeauData] = useState([]);
  const [bandeau, setBandeau] = useState({
    startDate: null,
    endDate: null,
    title: "",
    contenu: "",
    contentEN: "",
    owner: 0,
    supplier: 0,
    ADMIN: 1,
    ACCOUNTING: 0,
    ACCOUNTING_TLV: 0,
    REJECT_MANAGER: 0,
    BUYER: 0,
    couleur:"#ff0000",
    iconographieType:null
  });


  useEffect(() => {
    if (uid != null) {
      Axios.get("user/" + uid + "/bandeauByUid")
        .then((response) => {
          setBandeau(response.data.result);
        })
        .catch((response) => {
          notyf.error("Une erreur s'est produite");
        });
    }
  }, []);

  const handleStartDateChange = (date) => {
    if(date!=null){
    if (date?.toString() === "Invalid Date") {
      setStartDateError(true);
    } else {
      if( date.getFullYear <= 1900 || date.getFullYear > 2100 ||date.getTime() > bandeau?.endDate && bandeau?.endDate != null){
        notyf.error("La date de début doit être inférieure ou égale à la date de fin")
      }else{
      date.setHours(0, 0, 0, 0);
      setBandeau({
        ...bandeau,
        startDate: date.getTime(),
      });
    }
    }
  }
  };

  const handleEndDateChange = (date) => {
    if(date!=null){
    if (date?.toString() === "Invalid Date") {
      setEndDateError(true);
    } else {
      if(date.getFullYear <= 1900 || date.getFullYear > 2100 || date.getTime() < bandeau?.startDate && bandeau?.startDate != null){
        notyf.error("La date de début doit être inférieure ou égale à la date de fin")}
        else{
          date.setHours(23, 59, 59, 999)
          setBandeau({
            ...bandeau,
            endDate: date.getTime(),
          });
    }
    }
  }
  };

  const optionChangeHandler = (name, value) => {
    // const name = e.target.name;
    // const value = e.target.value;

    setBandeau({
      ...bandeau,
      [name]: value == 0 ? false : true,
    });
  };

  const titleChangeHandler = (e) => {
    const { value, name } = e.target;
    setBandeau({
      ...bandeau,
      [name]: value,
    });
  };

  const checkCampaignForm = () => {
    let errors = [];
    if (bandeau?.startDate == null)
      errors.push("Date de début de bandeau invalide");
    if (bandeau?.endDate == null)
      errors.push("Date de fin du bandeau invalide");

      if (bandeau?.endDate <bandeau?.startDate)
      errors.push("Date de fin doit être supérieure à la date de debut");
    if (bandeau?.title.length === 0) errors.push("Veuillez saisir le titre");

    if (bandeau?.contenu.length === 0)
      errors.push("Veuillez saisir le contenu du bandeau");
    return errors;
  };

  const updateBandeau = () => {
    Axios.put("user/" + uid + "/bandeauByUid", bandeau)  
      .then((response) => {
        notyf.success("Le bandeau a été mis à jour");
        navigate("/infobands");
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
      });
  };
  const createBandeau = () => {
    Axios.post("/user//addBandeau", bandeau)
      .then((response) => {
        notyf.success("Le bandeau a bien été créé");
        // setCreateCampaignLoading(false);
        setBandeau({
          startDate: null,
          endDate: null,
          supplier: 0,
          owner: 0,
          title: "",
          contenu: "",
          contenuEN: ""
        });
        navigate(-1);
      })
      .catch((response) => {
        notyf.error("Une erreur s'est produite");
        // setCreateCampaignLoading(false)
      });
  };

  const createbandeauClickHandler = () => {
    let errors = checkCampaignForm();
    if (errors.length > 0) setCampaignErrors(errors);
    else {
      setCampaignErrors([]);
      if (!uid) createBandeau();
      else updateBandeau();
      console.log(bandeau);
    }
  };
  const inputChangeHandler2 = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    console.log(e.target.name);
    // setSelectedOption(e.target.value)
    switch (value) {
      case "warning":
        let docTemp = { 
          ...bandeau,
          iconographieType: value,
        };
        setBandeau(docTemp);
        break;
      case "error":
        let docTempp = {
          ...bandeau,
          iconographieType: value,
        };
        setBandeau(docTempp);
        break;
        case "null":
          let docTemppp = {
            ...bandeau,
            iconographieType: null,
          };
          setBandeau(docTemppp);
          break;

      default:
        break;
    }
  };

  return (
    <>
      <CFContainer>
        <CFHeaderWrapper>
          <CFHeader>
            {/* <CFHeaderTitle>{"Nouveau bandeau"}</CFHeaderTitle> */}
            <CFHeaderTitle style={{marginLeft: "95px"}}>{"Nouveau bandeau"}</CFHeaderTitle>
            <CFHeaderActions>
              {/* <CFHeaderTitle style={{marginLeft: "95px"}}>{t("menu:newCampaign", "Nouvelle campagne")}</CFHeaderTitle> */}
              <BtnOutlineAction
                color={theme.colors.primary}
                onClick={() => createbandeauClickHandler()}
              >
                <span>Valider</span>
              </BtnOutlineAction>

              {/* <BtnOutlineAction
                                color={theme.colors.danger}
                                border={theme.colors.redLight}
                                onClick={() => navigate(-1)}
                            >
                                <span>RETOUR</span> 
                            </BtnOutlineAction> */}
            </CFHeaderActions>
          </CFHeader>
        </CFHeaderWrapper>
        {bandeau.contenu && (
                <div
                  style={{
                    backgroundColor: bandeau.couleur,
                    padding: "25px",
                    display: "flex",
                    borderRadius: "5px",
                    color:"#fff"
                  }}
                >
                  {bandeau.iconographieType === "warning" ? (
                    <WarningAmberIcon style={{ marginRight: "20px" }} />
                  ) :bandeau.iconographieType === "error"? (
                    <ErrorOutlineIcon style={{ marginRight: "20px" }} />
                  ):null}
                  <p
                    style={{
                      whiteSpace: "pre-wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {bandeau.contenu}
                  </p>
                </div>
              )}
        <CFBody>
          {campaignErrors && campaignErrors?.length > 0 ? (
            <div
              className="alert alert-danger mt-2 campaignForm__errors_container"
              role="alert"
            >
              <ul>
                {campaignErrors?.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <CFSection>
            <CFFormWrapper>
              <CFFormSection>
                <CFFormGroup>
                  <FormLabel htmlFor="title">Titre<MandatoryMarker /> :</FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    placeHolder="Titre"
                    value={bandeau?.title || bandeau?.title || ""}
                    onChange={(e) => titleChangeHandler(e)}
                    maxLength={255}
                    name="title"
                  />
                </CFFormGroup>
          <CFFormGroup>
            
            <BandCouleurs  bandeau={bandeau} setBandeau={setBandeau}/>
          </CFFormGroup>

                <CFFormGroup>
                  <EIFormLabel
                    style={{ marginRight: "17%" }}
                    for="lien"
                    className="accountRequest__canalItemLabel"
                  >
                    Iconographie :
                  </EIFormLabel>
                  
                  <div>
                    <InputCheckbox
                      type="radio"
                      style={{ marginRight: "10px" }}
                      id="doc"
                      name="web"
                      value="null"
                      onChange={inputChangeHandler2}
                      checked={bandeau.iconographieType == null}
                    />
                   {"Aucune"}
                  </div>
                  <div >
                    
                    <InputCheckbox
                      type="radio"
                      style={{ marginRight: "10px" }}
                      id="lien"
                      value="warning"
                      name="web"
                      onChange={inputChangeHandler2}
                      checked={bandeau.iconographieType === "warning"}
                    />
                    <CTooltip title={t('tableBody:ena',"Très important" )}  className="tableActions__icon">
                      <WarningAmberIcon />
                    </CTooltip>
                  </div>

                  <div>
                    <InputCheckbox
                      type="radio"
                      style={{ marginRight: "10px" }}
                      id="doc"
                      name="web"
                      value="error"
                      onChange={inputChangeHandler2}
                      checked={bandeau.iconographieType == "error"}
                    />
                    <CTooltip title={t('tableBody:ena',"Important" )}  className="tableActions__icon">
                      <ErrorOutlineIcon />
                    </CTooltip>
                  </div>
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="contenu">Contenu<MandatoryMarker /> : </FormLabel>  

                  <TextareaAutosize
                    maxRows={4}
                    id="contenu"
                    type="text"
                    placeHolder="Contenu"
                    value={bandeau?.contenu || bandeau?.contenu || ""}
                    onChange={(e) => titleChangeHandler(e)}
                    style={{ width: "70%" }}
                    name="contenu"
                    maxlength="200"
                  />
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="contentEN">Contenu version Anglais : </FormLabel>
                  <TextareaAutosize
                    maxRows={4}
                    id="contentEN"
                    type="text"
                    placeHolder="Contenu version Anglais"
                    value={bandeau?.contentEN || bandeau?.contentEN || ""}
                    onChange={(e) => titleChangeHandler(e)}
                    style={{ width: "70%" }}
                    name="contentEN"
                    maxlength="200"
                  />
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel>Période de validité<MandatoryMarker /> :</FormLabel>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={frLocale}
                  >
                    <DPContainer>
                      <DatePicker
                        id="date-picker-dialog"
                        label="Date début "
                        format="dd/MM/yyyy"
                        minDate={selectedStartDate}
                        maxDate={bandeau?.endDate ? new Date(+bandeau?.endDate) : null}
                        value={bandeau?.startDate? new Date(+bandeau?.startDate): selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        invalidDateMessage="Format de date invalide"
                        renderInput={(params) => <TextField {...params} />}
                        // open={true} control open dialog state
                      />
                    </DPContainer>
                    <DPContainer>
                      <DatePicker
                        id="date-picker-dialog"
                        label="Date fin "
                        format="dd/MM/yyyy"
                        minDate={bandeau?.startDate ? new Date(+bandeau?.startDate) :selectedStartDate}
                        //maxDate={bandeau?.endDate ? new Date(+bandeau?.endDate) : null}
                        value={bandeau?.endDate ? new Date(+bandeau?.endDate) : null }
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        invalidDateMessage="Format de date invalide"
                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </DPContainer>
                  </LocalizationProvider>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel style={{ fontWeight: "bold" }}>Visibilité :</ToggleLabel>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel active={+bandeau?.supplier}>
                    Fournisseurs
                  </ToggleLabel>
                  <ToggleIcon active={bandeau?.supplier}>
                    {+bandeau?.supplier ? (
                      <ToggleOnIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("supplier", 0)}
                      />
                    ) : (
                      <ToggleOffIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("supplier", 1)}
                      />
                    )}
                  </ToggleIcon>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel style={{ fontWeight: "bold" }}>
                    Utilisateurs Transdev
                  </ToggleLabel>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel active={+bandeau?.ACCOUNTING}>
                    Comptable
                  </ToggleLabel>
                  <ToggleIcon active={+bandeau?.ACCOUNTING}>
                    {+bandeau?.ACCOUNTING ? (
                      <ToggleOnIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("ACCOUNTING", 0)}
                      />
                    ) : (
                      <ToggleOffIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("ACCOUNTING", 1)}
                      />
                    )}
                  </ToggleIcon>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel active={+bandeau?.ACCOUNTING_TLV}>
                    Comptable TLV
                  </ToggleLabel>
                  <ToggleIcon active={+bandeau?.ACCOUNTING_TLV}>
                    {+bandeau?.ACCOUNTING_TLV ? (
                      <ToggleOnIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("ACCOUNTING_TLV", 0)}
                      />
                    ) : (
                      <ToggleOffIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("ACCOUNTING_TLV", 1)}
                      />
                    )}
                  </ToggleIcon>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel active={+bandeau?.REJECT_MANAGER}>
                    Gestionnaire conformité
                  </ToggleLabel>
                  <ToggleIcon active={+bandeau?.REJECT_MANAGER}>
                    {+bandeau?.REJECT_MANAGER ? (
                      <ToggleOnIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("REJECT_MANAGER", 0)}
                      />
                    ) : (
                      <ToggleOffIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("REJECT_MANAGER", 1)}
                      />
                    )}
                  </ToggleIcon>
                </CFFormGroup>
                <CFFormGroup>
                  <ToggleLabel active={+bandeau?.BUYER}>
                    Gestionnaire Achats
                  </ToggleLabel>
                  <ToggleIcon active={+bandeau?.BUYER}>
                    {+bandeau?.BUYER ? (
                      <ToggleOnIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("BUYER", 0)}
                      />
                    ) : (
                      <ToggleOffIcon
                        fontSize="small"
                        onClick={() => optionChangeHandler("BUYER", 1)}
                      />
                    )}
                  </ToggleIcon>
                </CFFormGroup>
              </CFFormSection>
             
            </CFFormWrapper>
          </CFSection>
        </CFBody>
      </CFContainer>
    </>
  );
}

export default NewInfoband;
