import React, { useCallback, useEffect, useRef, useState } from 'react'
import Axios from '../../../axios-proas'
import { TD, FilterContainer, SelectFilter, Option, InputFilter } from '../../../styles/Table.styled'
import DateFilter from '../../UI/DateFilter/DateFilter';
import AmountFilter from '../../UI/AmountFilter/AmountFilter';
import { useTranslation } from 'react-i18next';
import {showColumn} from '../../../shared/utility' 
import { CTooltip } from '../../UI/CTooltip/CTooltip';

function TableFilters(props) {
    const { filters, filtersChange, applyFilters,userType, userLevel, amountBTTotal, amountATTotal, userRole, entityFilter, columns, statuses, multiEntities, userProfile, isFuture, isLitigation} = props;
    const {t} = useTranslation();
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const getAmountBT = (field) => {
        if(filters?.exclusiveTaxAmount){
            let amount = filters?.exclusiveTaxAmount.split(":");
            if (field === "start")
                return amount[0]
            else return amount[1]
        }else{
            return '';
        }   
    }
    const [show, setShow] = useState(null)
    const [rangeShow, setRangeShow] = useState(false);
    const [rangeBTShow, setRangeBTShow] = useState(false);
    const [dateField, setDateField] = useState('')
    
    const [amountBT, setAmountBT] = useState(null)
    const [amountAT, setAmountAT] = useState(null)
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        Axios.get('/region').then(response => {
            setRegions(response.data[0])
        }).catch(err => {
            console.log(err);
        })
    } ,[])

    const rangeBTModalHandler = () =>{
        setRangeBTShow(true);
        getAmountBTValues();
    }
    const getAmountBTValues = () => {
        Axios.get("invoice//amountBTValues",{
            params: {visualisation : filters.visualisation}
        }).then(response => {
            setAmountBT({
                start: +response.data.minAmountBT,
                end: +response.data.maxAmountBT
            })
        })
    }

    const rangeATModalHandler = () =>{
        setRangeShow(true);
        getAmountATValues();
    }
    const getAmountATValues = () => {
         Axios.get("invoice//amountATValues",{
           params: {visualisation : filters.visualisation}
       }).then(response => {
             setAmountAT({
               start: +response.data.minAmountAT,
                end: +response.data.maxAmountAT
          })
       })
    }
    
    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    let [dateInputValue,setDateInputValue] = useState({
        issuingDate: filters?.issuingDate ? convertDate(filters.issuingDate) : '',
        dueDate: filters?.dueDate ? convertDate(filters.dueDate) : '',
        statusDate: filters?.statusDate ? convertDate(filters.statusDate) : '',
        creationDate: filters?.creationDate ? convertDate(filters.creationDate) : ''
    });

    const getIssuingDate = () => {
        return filters?.issuingDate ? convertDate(filters.issuingDate) : [];
    }

    const getStatusDate = () => {
        return filters?.statusDate ? convertDate(filters.statusDate) : [];
    }

    const getCreationDate = () => {
        return filters?.creationDate ? convertDate(filters.creationDate) : [];
    }
    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const closeAmountModal = () => {
        setRangeShow(false);
    }

    const closeAmountBTModal = () => {
        setRangeBTShow(false);
    }


    const getAmountAT = (field) => {
        if(filters?.inclusiveTaxAmount){
            let amount = filters?.inclusiveTaxAmount.split(":");
            if (field === "start")
                return amount[0]
            else return amount[1]
        }else{
            return '';
        }   
    }
    
    const amountAtChanged = (startval, endval) => {
        setAmountAT({
            start: startval,
            end: endval
        })
        let newFilters = {
            ...filters,
            inclusiveTaxAmount: startval + ':' + endval
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountATReset = () => {
        let newFilters = {
            ...filters,
            inclusiveTaxAmount: null
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountBtChanged = (startval, endval) => {
        setAmountBT({
            start: startval,
            end: endval
        })
        let newFilters = {
            ...filters,
            exclusiveTaxAmount: startval + ':' + endval
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    const amountBTReset = () => {
        let newFilters = {
            ...filters,
            exclusiveTaxAmount: null
        };
        filtersChange(newFilters);
        applyFilters(newFilters);
    }  

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        if (e.target.value?.length >= 3 || e.target.tagName !== "INPUT")
            applyFilters(newFilters);
        else if (e.target.value?.length < 3 && filters[key]?.length >= 3) {
            newFilters[key] = "";
            applyFilters(newFilters);
        }
    }
    


  


    return (
        <>
           
            {
            <tr style={{position: "sticky",
                        top: "38px",
                        backgroundColor: "white",
                        zIndex: "99"}}>
                <TD></TD>

                {entityFilter === "client" &&
                 <TD style={{display: !showColumn(columns,"firstAccessDate") && 'none'}} >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.firstAccessDate || ''} 
                            name="firstAccessDate" 
                            >
                            <Option value=""></Option>
                            <Option value="0">{t("global:no", "No")}</Option>
                            <Option value="1">{t("global:yes", "Yes")}</Option>
                        </SelectFilter>
                        {/* <VisibilityIcon style={{color: ""}} />  */}
                    </FilterContainer>  
                </TD>}
                {entityFilter === "supplier" &&
                <TD style={{display: !showColumn(columns,"attachmentFile")&& 'none'}}></TD>}
                {
                    userType==='supplier' || userType==='owner' ? (
                        <><TD style={{display: !showColumn(columns,"clientCode")&& 'none'}} >
                            <FilterContainer>
                                <InputFilter 
                                    placeholder={t("entitiesList:entityCode", "SU code")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={filters?.clientCode || ''} 
                                    name="clientCode" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                        </TD>
                        <CTooltip title={filters?.clientName || ''}>
                        <TD style={{display: !showColumn(columns,"clientName")&& 'none'}} >
                            <FilterContainer>
                                <InputFilter 
                                    placeholder={t("entitiesList:entityName", "Entity")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={filters?.clientName || ''} 
                                    name="clientName" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                        </TD>
                        </CTooltip></>
                    )
                    :null
                }
                {
                    userType!=='supplier' || multiEntities ? (
                        <>
                    <CTooltip title={filters?.supplierCode || ''}>
                        <TD style={{display: !showColumn(columns,"supplierCode") && 'none'}}>
                            <FilterContainer>
                                <InputFilter 
                                    placeholder={t("supplier:supplierCodefournisseur", "Supplier code")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={filters?.supplierCode || ''} 
                                    name="supplierCode" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                        </TD>
                        </CTooltip>
                        <TD style={{display: !showColumn(columns,"supplierName") && 'none'}}>
                            <FilterContainer>
                                <InputFilter 
                                    placeholder={t("invoiceList:supplier", "Supplier")}
                                    onChange={(e) => inputChangeHandler(e)} 
                                    value={filters?.supplierName || ''} 
                                    name="supplierName" 
                                    type="text" 
                                    autoComplete="off" />
                            </FilterContainer>
                        </TD></>
                    )
                    :null
                }
                {userType === "owner" &&
                 <TD style={{display: !showColumn(columns,"channel") && 'none'}} >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.channel || ''} 
                            name="channel" 
                            >
                            <Option value="">Type de dépôt</Option>
                            <Option value="PAPER">Papier </Option>
                            <Option value="EMAIL">Email</Option>
                            <Option value="PORTAL">Portail</Option>
                            <Option value="EDI">EDI</Option>
                        </SelectFilter>
                        {/* <VisibilityIcon style={{color: ""}} />  */}
                    </FilterContainer>  
                </TD>}
                
                <TD style={{display: !showColumn(columns,"documentType") && 'none'}}>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.documentType || ''} 
                        name="documentType" 
                        id="exampleFormControlSelect2"
                    >
                        <Option value="">{t("invoiceList:docType")}</Option>
                        <Option value="INV">{t("invoiceList:invoice")}</Option>
                        <Option value="CRN">{t("invoiceList:creditNote")}</Option>
                        {/* <Option value="UNK">{t("invoiceList:unknown")}</Option> */}
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"number") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:number", "Number")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.number || ''} 
                            name="number" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"issuingDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:issuingDate", "Issuing date")}
                            from={getIssuingDate()[0] || ''}
                            to={getIssuingDate()[1] || ''}
                            name="issuingDate"
                            activeReset={filters?.issuingDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.issuingDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                {isFuture && userProfile === "REJECT_MANAGER" && <TD>
                <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.futureValidated || ''} 
                        name="futureValidated" 
                        id=""
                    >
                        <Option value=""></Option>
                        <Option value="1">Validée</Option>
                        <Option value="0">Non validée</Option>
                    </SelectFilter></TD>}
                <TD style={{display: !showColumn(columns,"status") && 'none'}}>
                    {isLitigation ? <div>En litige</div> : 
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.status || ''} 
                        name="status" 
                        >
                        <Option value="">{t("invoiceList:status", "Status")}</Option>
                        {statuses?.concat([{id: "err51", label: "Doublon"}, {id: "err52", label: "Faux doublon"}, {id: "permanentReject", label: "Clôturé"}, {id: "permanentRejectErr04x", label: "Clôturé err04x"}]).map(s => <Option value={s.id}>{t("statuses:"+s.label, s.label)}</Option>)}
                    </SelectFilter>
                    </FilterContainer>}
                </TD>
                <TD style={{display: !showColumn(columns,"statusDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:statusDate", "Status change date")}
                            from={getStatusDate()[0] || ''}
                            to={getStatusDate()[1] || ''}
                            name="statusDate"
                            activeReset={filters?.statusDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.statusDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"unreadMessages")&& 'none'}} >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.unreadMessages || ''} 
                            name="unreadMessages" 
                            id=""
                        >
                            <Option value="">{t("invoiceList:comments", "Comments")}</Option>
                            <Option value="0">{t("invoiceList:read", "Read")}</Option>
                            <Option value="1">{t("invoiceList:unread", "Unread")}</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"region")&& 'none'}} >
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.region || ''} 
                            name="region" 
                            id=""
                        >
                            <Option value="">{t("invoiceList:region", "Region")}</Option>
                            {regions.map(r => <Option key={r.id} value={r.id}>{r.name}</Option>)}
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                
                <TD style={{display: !showColumn(columns,"inclusiveTaxAmount") && 'none'}}>
                    <FilterContainer>
                        <AmountFilter
                            modalTitle={t("invoiceList:inclusiveAmount")} 
                            from={getAmountAT("start") || ''}
                            to={getAmountAT("end") || ''}
                            total={amountATTotal}
                            name="inclusiveTaxAmount"
                            activeReset={filters?.inclusiveTaxAmount ? true : false} 
                            resetInterval={amountATReset}
                            defaultVal={amountAT} 
                            defaultValStart={+getAmountAT("start") || amountAT?.start} 
                            defaultValEnd={+getAmountAT("end") || amountAT?.end}   
                            value={getAmountAT}
                            show={rangeShow}  
                            showModal={rangeATModalHandler} 
                            closeModal={closeAmountModal} 
                            amountChanged={amountAtChanged}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"exclusiveTaxAmount")&& 'none'}}>
                    <FilterContainer>
                        <AmountFilter
                            modalTitle={t("invoiceList:exclusiveAmount")} 
                            from={getAmountBT("start") || ''}
                            to={getAmountBT("end") || ''}
                            total={amountBTTotal}
                            name="exclusiveTaxAmount"
                            activeReset={filters?.exclusiveTaxAmount ? true : false} 
                            resetInterval={amountBTReset}
                            defaultVal={amountBT}
                            defaultValStart={+getAmountBT("start") || amountBT?.start} 
                            defaultValEnd={+getAmountBT("end") || amountBT?.end}  
                            value={getAmountBT}
                            show={rangeBTShow}  
                            showModal={rangeBTModalHandler} 
                            closeModal={closeAmountBTModal} 
                            amountChanged={amountBtChanged}
                        />
                    </FilterContainer>  
                </TD>
                <TD style={{display: !showColumn(columns,"currency")&& 'none'}}>
                    <FilterContainer>
                    <InputFilter 
                            placeholder={t("invoiceList:currency", "Currency")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.currency || ''} 
                            name="currency" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                {userType === "owner" && <>
                <TD style={{display: !showColumn(columns,"creationDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={"Date d'acquisition"}
                            from={getCreationDate()[0] || ''}
                            to={getCreationDate()[1] || ''}
                            name="creationDate"
                            activeReset={filters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"documentCode")&& 'none'}} >
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Code document"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.documentCode || ''} 
                            name="documentCode" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"internalNumber")&& 'none'}} >
                    <FilterContainer>
                        <InputFilter 
                            placeholder="Numéro de facture interne"
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.internalNumber || ''} 
                            name="internalNumber" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD></>}
                {!["ACCOUNTING","ACCOUNTING_TLV"].includes(userProfile) && <TD></TD>}
            </tr>}
        </>
    )
}

export default TableFilters
